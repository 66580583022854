import {DoubleRightOutlined, FileAddOutlined, InfoCircleOutlined} from "@ant-design/icons";
import AssignmentImage from "../../../assets/images/assignment-image.svg";
import "./assignmentAccordion.css";
import React, { useState } from "react";
import Tooltip from "antd/lib/tooltip";
import {Alert, Button, Image} from "antd";
import logoBtn from "../../../assets/images/logo-btn.svg";
import CustomSpin from "../../customSpin/CustomSpin";
import Correction from "../../../Pages/Correction/Correction";
import TextEditor from "../../SlateEditor/TextEditor";
import {useEditStudentSubmissionContentMutation} from "../../../services/studentSubmission.api";
import Swal from 'sweetalert2';
import {useCreateStudentSubmissionAssismentMutation} from "../../../services/studentsubmitionassisment.api"; // Import SweetAlert2




const AssignmentAccordion = ({data,updatedCriteriaScore,
                               updatedFeedBacks}) => {
  const [accordionStates, setAccordionStates] = useState({});
  const [isAssignmentImageExist] = useState(true);
  const [newContent, setNewContent] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ])
  const [editStudentSub] = useEditStudentSubmissionContentMutation()
  const [editStudentSubContent] = useCreateStudentSubmissionAssismentMutation()

  const handleContentChange = (content) => {
  setNewContent(content)
  };
  const handleSubmit = async () =>{
    console.log("id222data",data)
    if (data.studentCourseWork.studentSubmission){
      console.log("id222",data.id)
      console.log("updatedFeedBacks",updatedFeedBacks)
      console.log("updatedCriteriaScore",updatedCriteriaScore)
      // merge 2 arrays updatedCriteriaScore and updatedFeedBacks
      const corrected={feedbacks: updatedFeedBacks, scores: updatedCriteriaScore}
      // parse corrected array to json
      const mergedArray = JSON.stringify(corrected)
      console.log("mergedArray",mergedArray)
      const  { data:dataEdit, error } = await editStudentSub({id:data.id,state:"viewed"})
      const  {data: dataStudentSubmission, error: errorStudentSubmission} = await editStudentSubContent(
          {
            corrected_text: {feedbacks: updatedFeedBacks, scores: updatedCriteriaScore,'studentSubmissionAfterCorrection':newContent},
            studentSubmission: `/api/student-submissions/${data.id}`,
            created_at: new Date().toISOString(),
            type: "overrided correction",
          })
      console.log("data update",dataEdit)


      if (dataEdit || dataStudentSubmission){
        // alert("Data updated successfully")
        console.log("data update",dataEdit)
        return Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'Data updated successfully',
          confirmButtonText: 'OK',
          confirmButtonColor: '#3085d6',
          timer: 3000 // Optional: Auto close after 3 seconds
        });
      }
      if (error || errorStudentSubmission){
        alert("Error, We encountered an issue while trying to update the assesment . Please check your input and try again. If the problem persists, contact support for assistance.")
        console.log("error update",error)
      }
    }



    console.log('updatedCriteriaScore submit',updatedCriteriaScore)
    console.log('updatedFeedBacks submit',updatedFeedBacks)
  }
  const toggle = (index) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  let title=""
  let description="Write an essay describing your journey as an explorer traveling across the continent of Africa. Include detailed observations and experiences on the natural landscapes, climates, unique flora and fauna, cultural diversity, natural and food resources, and your reflections on the young population and the continent's potential. Explain why this journey would be significant to yoervations and experiences on the natural landscapes, climates, unique flora and fauna, cultural diversity, natural and food population and the continent's potential. Explain why this journey would be significant to yoervations and experiences on the natural ."
  let shortDescription=""
  let content=""
  let student=""
  let image=""
  if(data) {
    if (data.studentCourseWork && data.studentCourseWork.coursework) {
      let coursework = data.studentCourseWork.coursework
      student = data.studentCourseWork.student.first_name + " " + data.studentCourseWork.student.family_name
      content = data.content
      title = coursework.title
      description = coursework.description
      shortDescription = description.substring(0, 30) + "..."
      if(coursework.image)  image = coursework.image.filePath
    }
  }else{
    // render a loading spinner
    return  <CustomSpin />
  }
  return (
      <>
        <div  id={"right"}>
          <div className={`accordion`}>
            <div className="accordion__title" onClick={() => toggle(1)}>
              <Tooltip title={shortDescription} placement={"top"}>
                <h1>{title}</h1>
              </Tooltip>

              <DoubleRightOutlined className={`arrow ${accordionStates[1] ? "toggled" : ""}`}/>
            </div>
            <div className={`accordion__content  ${accordionStates[1] ? "toggled" : ""}`}>
              <img src={image ? `${process.env.REACT_APP_API_BASE_URL}/uploads/images/${image}` : AssignmentImage} alt={title} />
              <div>
                <h1>{title}</h1>
                <p>
                  {description}
                </p>
              </div>
            </div>
          </div>

          <div className={`accordion`}>
            <div className="accordion__title-student" onClick={() => toggle(2)}>
              <h1>Student Couresework  {student &&   <span style={{color: 'white',fontWeight:500}}> <i>By : {student}</i></span>}</h1>


              <DoubleRightOutlined className={`arrow ${accordionStates[2] ? "toggled" : ""}`}/>
            </div>
            <div className={`accordion__content-student  ${ accordionStates[2] ? "toggled" : ""}`}>
              <TextEditor  studentCourseWork={data}  onContentChange={handleContentChange}/>

            </div>

          </div>
        </div>
        <Button type="primary" onClick={handleSubmit}
                style={{marginLeft: 8, float: 'right', marginTop: 6, marginBottom: 10 }}  className={"button button-big btn-correction"}>
          <Image width={20} src={logoBtn} alt="Background"  preview={false}/>
          Submit
        </Button>
      </>
  );
};

export default AssignmentAccordion;