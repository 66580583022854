import { UpOutlined } from "@ant-design/icons";
import "./rubricScoresAccordion.css";
import { useState } from "react";

const RubricScoresAccordion = ({ criteriaTitle, scores, criteriaIndex, criteriaUsage,scoreExplanation }) => {
    const [accordionStates, setAccordionStates] = useState({});
    const [content, setContent] = useState(
        scoreExplanation
    );
    const [activeScores, setActiveScores] = useState(scores-1);
    const handleContentChange = (event, index) => {
        setContent(event.target.value);
        criteriaUsage(index,activeScores, event.target.value);
    };

    const toggle = (criteriaIndex) => {
        setAccordionStates((prevState) => ({
            ...prevState,
            [criteriaIndex]: !prevState[criteriaIndex],
        }));
    };

    const handleScoreClick = (scoreIndex) => {
        const newScore = activeScores !== null ? scoreIndex+1 : 0;
        console.log('newScore',newScore)
        if (scoreIndex === activeScores){
            setActiveScores(null)
        }else{
            setActiveScores(scoreIndex)
        }
        criteriaUsage(criteriaIndex, newScore, content);
    };


    return (
        <div className="rubricAccordion">
            <div className="rubricAccordion__title">
                <div
                    className="rubricAccordion__header"
                    onClick={() => toggle(criteriaIndex)}
                >
                    <div className="rubricAccordion__header-content">
                        <h1 className="rubricAccordion__header-title">
                            {criteriaTitle}
                        </h1>
                        <span className="rubricAccordion__header-score">
                            {activeScores !== null ? activeScores+1 : 0}/
                            {scores}
                        </span>
                    </div>
                    <UpOutlined
                        className={`rubricAccordion__icon ${
                            accordionStates[criteriaIndex] ? "toggled" : ""
                        }`}
                    />
                </div>
                <div className="rubricAccordion__footer">
                    {Array.from({ length: scores }).map((_, scoreIndex) => (
                        <div
                            key={scoreIndex}
                            className={`rubricAccordion__footer-score ${
                                (activeScores !== null && activeScores >= scoreIndex) ? "active" : ""
                            }`}
                            onClick={() => handleScoreClick(scoreIndex)}
                        />
                    ))}
                </div>
            </div>
            <div
                className={`rubricAccordion__content ${
                    accordionStates[criteriaIndex] ? "toggled" : ""
                }`}
            >
                <textarea
                    onChange={(e)=>handleContentChange(e, criteriaIndex)}
                    value={content}
                />
            </div>
        </div>
    );
};

export default RubricScoresAccordion;