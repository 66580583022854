import { Progress } from 'antd';
import "./analyticsProgress.css";


const AnalyticsProgress = ({ label, percentage , progressColor="#000095" }) => {
  return (
    <div className="analytics-item">
        <strong className="analytics-percentage" style={{color:progressColor}}>
            {percentage}%
        </strong>
        <Progress
        percent={percentage}
        showInfo={false}
        size={["default", 19]}
        className="analytics-progress"
        strokeColor={progressColor}
        />
        <span className="analytics-label">{label}</span>
    </div>
  );
};

export default AnalyticsProgress;
