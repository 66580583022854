import AppLayout from "../Layout/AppLayout";
import Table from "../../Components/table/Table";
import "./submissions.css"
import {useEffect, useState} from "react"
import {Button, Dropdown, Input, Spin} from "antd";
import Filter from "../../assets/images/filter.svg";
import {FormOutlined, SearchOutlined} from "@ant-design/icons";
import Code from "../../assets/images/code.svg";
import {useNavigate} from "react-router-dom";
import {useLazyGetAssignmentsQuery} from "../../services/assignments.api";
import {useLazyGetStudentSubmissionsQuery} from "../../services/studentsubmissions.api";
import moment from 'moment';
import data from "./data";
import {useLazyGetRubricsQuery} from "../../services/rubrics.api";

const Submissions = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const [triggerAssignment, {data: assignmentData, isLoading:isLoadingAssignments }] = useLazyGetAssignmentsQuery();
  const [trigger, { data: studentsubmissions, isLoading: isLoading}] = useLazyGetStudentSubmissionsQuery();

  useEffect(() => {
    triggerAssignment()
  }, [triggerAssignment]);

  useEffect(() => {
      trigger()
  }, [trigger]);
  console.log('studentsubmissions =>', studentsubmissions ? studentsubmissions['hydra:member']: []);

  const handlSidebarCollapse =()=>{
    setCollapsed(!collapsed)
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const addAssisement = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [checkedItems, setCheckedItems] = useState([]);


  const formatDate = (text) => {
    console.log('text+++++++++++++++', text);
    if (!text) {
      console.log('No date provided');
      return '';
    }
    return moment(text).format('YYYY-MM-DD');
  };

  const timeConverter = (rawDate) => {
    return moment(rawDate).format("MMM DD YYYY");
  }


  const columns = [
    { key: 1 , title: '', dataIndex: 'id'},
    {
      key: 2,
      title: 'Student Name',
      dataIndex:  ["student", "first_name"],
      render: (text, record) => record.studentCourseWork.student ? record.studentCourseWork.student.first_name + " " + record.studentCourseWork.student.family_name : ''     },
    { key: 3 , title: 'Assignment Title', dataIndex:  ["studentCourseWork.coursework", "title"], render: (text, record) => record.studentCourseWork.coursework.title ? record.studentCourseWork.coursework.title : ''},
    { key: 4 , title: 'Format', dataIndex:  "type", render: (text, record) => record.studentCourseWork.coursework.type ? record.studentCourseWork.coursework.type : ''},
    { key: 5 , title: 'Subject', dataIndex:  ["studentCourseWork.coursework", "subject"], render: (text, record) => record.studentCourseWork.coursework.subject ? record.studentCourseWork.coursework.subject : '' },
    { key: 6 , title: 'State', dataIndex: 'state' },
    {
      key: 7,
      title: 'Date submitted',
      dataIndex: 'created_at',
      // render: (text) => formatDate(text),
      render: (created_at) => timeConverter(created_at),

    },
    { key: 7 , title: 'Edit', dataIndex: 'edit'},
  ];
  console.log(columns,'columns');

  const handleCheckboxChange = (e, key) => {
    const { checked } = e.target;
    setCheckedItems((prev) =>
        checked ? [...prev, key] : prev.filter((item) => item !== key)
    );
  };
  const navigate = useNavigate();

  const handleActionClick = (id) => {
    console.log(id);
    navigate(`/submission/${id}/show`);
  };

  const TableHeadCell = (column) => {
    if (column.dataIndex === 'id' || column.dataIndex === 'edit') {
      return column.title;
    }
    else {
      return (
          <>
            <span>{column.title}</span>
            <img src={Code} alt="code icon" width={16} />
          </>
      );
    }
  };

  const TableBodyCell = (column, item) => {
    switch (column.dataIndex) {
      case 'edit':
        return (
            <Button onClick={() => handleActionClick(item.id)} className='tableBody__cell-openBtn' icon={<FormOutlined/>}>
            </Button>
        );
      case 'type':
        if(column.render){
          return <span className='tableBody__cell-formatCol'>{column.render(item[column.dataIndex], item)} </span>;
        }
        else {
          return item[column.dataIndex];
        }

      default:
        if(column.render){
         return column.render(item[column.dataIndex], item)
        }
        else {
            return item[column.dataIndex];
        }
    }
  };
console.log('studentsubmissionsffff =>', studentsubmissions ? studentsubmissions['hydra:member']: []);

  return (
      <AppLayout hideHeader={true}>
        <div className="content__submissions">
          <div className={"submission__top-page"}>
            <div className="submissions__filter">
              <span> Students Submissions</span>
            </div>

            <div className="submissions-new">
              <Input
                  type="text"
                  placeholder="search"
                  prefix={<SearchOutlined />}
                  value={searchTerm}
                  onChange={handleSearchChange}
              />
            </div>
          </div>
          <Table
              columns={columns}
              data={studentsubmissions ? studentsubmissions['hydra:member'] : []}
              TableHeadCell={TableHeadCell}
              TableBodyCell={TableBodyCell}
              noDataText={`You haven't added any submission yet.`}
              searchTerm={searchTerm}
              isLoading={true}
          />
        </div>
      </AppLayout>
  )
}

export default Submissions