import React from 'react';
import { Row, Col, Card } from 'antd';
import { Pie } from "@ant-design/charts";
import { PieChart } from "@mui/x-charts";
import Chart from 'react-apexcharts';
import {selectCorrection, selectIsCorrected} from "../../redux/app/app.selectors";
import {useSelector} from "react-redux";



const categoryMap = [
    {color: '#FF6633',type: 'ADJ' },
    {color: '#E6B333',type: 'ADP' },
    { color: '#80B300', type: 'ADV' },
    {color: '#800080', type: 'CCONJ'},
    {color: '#FFA500', type: 'DET'},
    {color: '#00B3E6', type: 'NOUN'},
    {color: '#991AFF', type: 'NUM'},
    {color: '#B33300', type: 'PART'},
    {color: '#809900', type: 'PRON'},
    {color: '#6680B3', type: 'PROPN'},
    {color: '#E666FF', type: 'PUNCT'},
    {color: '#7979a1', type: 'VERB'},
]

const PieChartData = () => {
    const correction = useSelector(selectCorrection);
    let wordTypes = []
    if (correction && correction.error_composition && correction.error_composition.length > 0) {
        wordTypes = correction.error_composition[1]
        console.log('wordTypes', wordTypes)
    }

    const initialTableData = categoryMap.map((item, index) => {
        const value = wordTypes[item.type] || 0;
        return {
            id: index,
            value: value,
            label: item.type,
            color: item.color,
        };
    });


    return (
<>
        <PieChart
            series={[
                {
                    data: initialTableData.map(item => ({
                        id: item.id,
                        value: item.value,
                        label: item.label,
                        color: item.color,
                    })),
                },
            ]}

            height={200}
        />

</>
)
    ;
};

export default PieChartData;