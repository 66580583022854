import { render } from '@testing-library/react';
import React , { useState,useEffect}from 'react';
import {Button} from "antd";
import './CardError.css';
import {SaveOutlined,CheckOutlined,DeleteOutlined,UndoOutlined} from "@ant-design/icons";


const CardError = (props) => {
    const [status,setStatus] = useState("error");
    const [phrase,setPhrase] = useState("");
    const {element,phrases,location,color,corrected} = props;

    // Effet pour mettre à jour la phrase avec les modifications
    useEffect(() => {
        if (phrases && location >= 0 && location < phrases.length) {
            const loc = Number(location);
            const start = Math.max(0, loc - 3);
            const end = Math.min(phrases.length, loc + 4);

            // Fonction pour vérifier si un mot est un caractère spécial
            const isSpecialChar = (word) => [',', '?', '!', '.'].includes(word);

            const before = phrases.slice(start, loc).map(word => isSpecialChar(word) ? '' : word);  // Éléments avant la position
            const after = phrases.slice(loc + 1, end).map(word => isSpecialChar(word) ? '' : word);  // 3 éléments après la position

            // Construction de la nouvelle phrase avec l'élément corrigé
            const newPhrases = [
                "...",
                ...before,
                `<span style="color: ${color};font-weight: 500">${element}</span>`,
                ...after,
                "..."
            ];
            setPhrase(newPhrases.join(' '));
        }
    }, [phrases, location, element]);
    return(
        <div className={"cardError"}>
            <h5>Correction</h5>
            <p dangerouslySetInnerHTML={{ __html: phrase }} />
            <hr className={"hr"}/>
            <div className="button-container">
                <Button
                    type="default"
                    icon={<SaveOutlined/>}
                    className="button button-card button-big"
                    onClick={()=>{props.reRenderWord(props.element,'save',corrected)} }

                >Save</Button>
                <Button
                    type="primary"
                    icon={corrected === false ?<CheckOutlined/>: <UndoOutlined />}
                    className="button button-big button-right"
                    onClick={()=>{props.reRenderWord(props.element,'correct',corrected)} }
                >{corrected === false ? 'Correct' : 'Undo correction'}</Button>
            </div>
        </div>
    )
};

export default CardError;