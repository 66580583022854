
import React, { useState } from "react";
import {Button, Input, Select, notification, Spin} from "antd";
import "./newRubric.css";
import AppLayout from "../Layout/AppLayout";
import Criteria from "../../Components/criteria/Criteria";
import { PlusCircleFilled } from "@ant-design/icons"
import {useNavigate} from "react-router-dom";
import { useFormik } from 'formik';
import { object, string, array, number } from 'yup';
import {useCreateRubricMutation} from "../../services/rubrics.api";


const NewRubric = () => {

    const [createRubric, {isLoading: isLoadingNewRubric}] = useCreateRubricMutation()

    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            title: '',
            type: '',
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            criterias: [
                {
                    title: '',
                    description: '',
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                    levels: [
                        {
                            title: '',
                            point:0,
                            description: '',
                            created_at: new Date().toISOString(),
                            updated_at: new Date().toISOString(),
                        }
                    ]
                },
            ],
        },
        validationSchema: object({
            title: string().required('Required'),
            criterias: array().of(
                object({
                    title: string().required('Required'),
                    description: string().required('Required'),
                    levels: array().of(
                        object({
                            title: string().required('Required'),
                            point: number().required('Required').min(0, 'Must be greater than or equal to 0'),
                            description: string().required('Required'),
                        })
                    )
                })
            )
        }),
        onSubmit: async (values) => {
            console.log("formik values ==>", values);

            if(values.criterias?.length === 0){
                api["error"]({
                    message: 'Rubric cannot be saved',
                    description:'Please add at least one criteria to your rubric.',
                    placement: 'top',
                    style: {
                        color:"red",
                    },
                    showProgress: true,
                    pauseOnHover:true,

                });
            }else{
                console.log("formik values ==>", values);

                 const {data, error} = await createRubric(values);
                     if(data){
                            api["success"]({
                                message: 'Rubric saved successfully',
                                description: 'You can now view your rubric in the list of rubrics.',
                                placement: 'top',
                                style: {
                                    color:"green",
                                },
                                showProgress: true,
                                pauseOnHover:true,

                            });
                        formik.resetForm();
                        // redirect to rubric list
                        navigate('/rubric');
                     }
                   if(error){
                       console.log("error", error)
                   }

            }
        },
    });

    const addCriteria = () => {
        formik.setFieldValue('criterias', [
            ...formik.values.criterias,
            { title: '',type: '', created_at: new Date().toISOString(), updated_at: new Date().toISOString() , levels: [{ title: '', point:0, description: '',  created_at: new Date().toISOString(), updated_at: new Date().toISOString()}] },
        ]);
    };

    const handleDeleteCriteria = (indexToDelete) => {
        const updatedCriteria = formik.values.criterias.filter((_, i) => i !== indexToDelete);
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const handleCriteriaTitleChange = (criteriaIndex,field, value) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex][field] = value;
        //updatedCriteria[criteriaIndex].description = value;
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const handleScoreChange = (criteriaIndex, scoreIndex, field, value) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex].levels[scoreIndex][field] = value;
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const addScore = (criteriaIndex) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex].levels.push({ title: '', point:0, description: '', created_at: new Date().toISOString(), updated_at: new Date().toISOString()});
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const deleteScore = (criteriaIndex, scoreIndex) => {
        const updatedCriteria = [...formik.values.criterias];
        if (scoreIndex !== 0) {
            updatedCriteria[criteriaIndex].levels = updatedCriteria[criteriaIndex].levels.filter((_, i) => i !== scoreIndex);
        } else {
            updatedCriteria[criteriaIndex].levels = [{ title: '', point:0, description: '' }];
        }
        formik.setFieldValue('criterias', updatedCriteria);
    };

    const deleteAllScores = (criteriaIndex) => {
        const updatedCriteria = [...formik.values.criterias];
        updatedCriteria[criteriaIndex].levels = [{ title: '', point:0, description: '' }];
        formik.setFieldValue('criterias', updatedCriteria);
    };

    return (
        <AppLayout hideHeader={true}>
            <div className="newRubricContent">
                <form onSubmit={formik.handleSubmit}>
                    <div className="newRubricContent__header">
                        <div className="newRubricContent__header-title">
                            <h1>New Rubric</h1>
                        </div>
                        <div className="newRubricContent__header-new">
                            <Button type="primary" htmlType="submit"> Save </Button>
                            {contextHolder}
                        </div>
                    </div>
                    <section>
                        <div className="rubric__form">
                            <div className="rubric__form-header">
                                <div>
                                    <label htmlFor="name" className="rubricNameInput">Name</label>
                                    <Input
                                        id="title"
                                        name="title"
                                        placeholder="Enter a rubric name"
                                        style={{width: "22rem"}}
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.title && formik.errors.title ?
                                        <div className='errorMessage'>{formik.errors.title}</div> : null}
                                </div>
                                <div>
                                    <label className="gradeSelector">Type</label>
                                    <Select
                                        style={{width: "12rem"}}
                                        onChange={(value) => formik.setFieldValue('type', value)}
                                        onBlur={() => formik.setFieldTouched('type')}
                                        options={[
                                            {value: 'table', label: 'Table'},
                                            {value: 'checklist', label: 'Checklist'},
                                        ]}
                                        placeholder="Select a type"
                                    />
                                    {formik.touched.type && formik.errors.type ?
                                        <div className='errorMessage'>{formik.errors.type}</div> : null}
                                </div>
                                {/*<div>*/}
                                {/*    <label htmlFor="scoreTotal" className="scoreTotal">Score total</label>*/}
                                {/*    <Input*/}
                                {/*        id="scoreTotal"*/}
                                {/*        readOnly*/}
                                {/*        // value={`${formik.values.scoreTotal}pt`}*/}
                                {/*        style={{width: "8rem"}}*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                            {formik.values.criterias.map((criteria, index) => (
                                <Criteria
                                    key={index}
                                    criteria={criteria}
                                    onDelete={() => handleDeleteCriteria(index)}
                                    onTitleChange={(field,value) => handleCriteriaTitleChange(index,field, value)}
                                    onScoreChange={(scoreIndex, field, value) => handleScoreChange(index, scoreIndex, field, value)}
                                    addScore={() => addScore(index)}
                                    deleteScore={(scoreIndex) => deleteScore(index, scoreIndex)}
                                    deleteAllScores={() => deleteAllScores(index)}
                                    error={formik.errors.criterias && formik.errors.criterias[index]}
                                />
                            ))}
                            <Button className="addCriteria" onClick={addCriteria}>
                                <PlusCircleFilled style={{fontSize: "18px"}}/>
                                <span>Add criteria </span>
                            </Button>
                        </div>
                    </section>
                </form>
            </div>
        </AppLayout>
    )
}

export default NewRubric
