import React, {useEffect, useState, useCallback, useContext} from 'react';
import { Tooltip } from "antd";
import CardError from './CardError';
import { CloseOutlined } from '@ant-design/icons';
import tinycolor from 'tinycolor2';
import './BlockElement.css';
import {string} from "slate";
import {textDecoration} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import {selectIsCorrected} from "../../redux/app/app.selectors";

const BlockElement = ({initial,element, type , need, location,phrases}) => {
    const [appeared, setAppeared] = useState("");
    const [isOpen,setIsOpen] = useState(false);
    const [color, setColor] = useState("");
    const [status,setStatus] = useState("error");
    const [corrected,setCorrected] = useState(false);
    const isCorrected = useSelector(selectIsCorrected);
    const handleTooltipClose = () => {
        setIsOpen(false); // Close the tooltip
    };


    function getSentences(){
        const newWords = phrases.map((word,index) => {
            return {
                word: typeof word == "string" ? word: word.props.initial,
                status: status ,
                corrected: corrected
            }
        })
        // parse newWords to string
        const sentence = newWords.map(wordObj => wordObj.word).join(' ');
        console.log("Sentence:", sentence);
    }
    // Liste des erreurs et leurs couleurs associées

    const data = [
        { color: '#FF6633', type: 'Adjective'},
        { color: '#E6B333',  type: 'Adjective form' },
        { color: '#80B300',  type: 'Adverb' },
        { color: '#FF99E6', type: 'Conjunction' },
        { color: '#66994D', type: 'Contraction' },
        { color: '#66664D',  type: 'Determiner' },
        { color: '#FFB399', type: 'Morphology' },
        { color: '#3366E6',  type: 'Noun' },
        { color: '#809900',  type: 'Noun Number' },
        { color: '#CCFF1A', type: 'Noun Possessive' },
        { color: '#B366CC',  type: 'Noun inflection' },
        { color: '#559f97', type: 'Orthography' },
        { color: '#a84b4b',  type: 'Other' },
        { color: '#999966', type: 'Particle' },
        { color: '#E6B3B3',  type: 'Preposition' },
        { color: '#FF1A66',  type: 'Pronoun' },
        { color: '#4D8000',  type: 'Punctuation' },
        { color: '#E666FF',  type: 'Spelling' },
        { color: '#adad46',  type: 'Subject-Verb Agreement' },
        { color: '#99FF99',  type: 'Unknown' },
        { color: '#6680B3',  type: 'Verb' },
        { color: '#E6331A',  type: 'Verb Form' },
        { color: '#B33300',  type: 'Verb Inflection' },
        { color: '#4DB3FF',  type: 'Verb Tense' },
        { color: '#b0ae3c',type: 'Word Order' },
    ];
    const rules = {
        'Adjective': ['M:ADJ', 'U:ADJ', 'R:ADJ'],
        'Adverb': ['M:ADV', 'U:ADV', 'R:ADV'],
        'Conjunction': ['M:CONJ', 'U:CONJ', 'R:CONJ'],
        'Contraction': ['M:CONTR', 'U:CONTR', 'R:CONTR'],
        'Determiner': ['M:DET', 'U:DET', 'R:DET'],
        'Morphology': ['M:MORPH', 'U:MORPH', 'R:MORPH'],
        'Noun': ['M:NOUN', 'U:NOUN', 'R:NOUN'],
        'Noun inflection': ['M:NOUN:INFL', 'U:NOUN:INFL', 'R:NOUN:INFL'],
        'Noun Number': ['M:NOUN:NUM', 'U:NOUN:NUM', 'R:NOUN:NUM'],
        'Noun Possessive': ['M:NOUN:POSS', 'U:NOUN:POSS', 'R:NOUN:POSS'],
        'Orthography': ['M:ORTH', 'U:ORTH', 'R:ORTH'],
        'Other': ['M:OTHER', 'U:OTHER', 'R:OTHER'],
        'Particle': ['M:PART', 'U:PART', 'R:PART'],
        'Preposition': ['M:PREP', 'U:PREP', 'R:PREP'],
        'Pronoun': ['M:PRON', 'U:PRON', 'R:PRON'],
        'Punctuation': ['M:PUNCT', 'U:PUNCT', 'R:PUNCT'],
        'Spelling': ['M:SPELL', 'U:SPELL', 'R:SPELL'],
        'Unknown': ['UNK'],
        'Verb': ['M:VERB', 'U:VERB', 'R:VERB'],
        'Verb Tense': ['M:VERB:TENSE', 'U:VERB:TENSE', 'R:VERB:TENSE'],
        'Verb Form': ['M:VERB:FORM', 'U:VERB:FORM', 'R:VERB:FORM'],
        'Subject-Verb Agreement': ['M:VERB:SVA', 'U:VERB:SVA', 'R:VERB:SVA'],
        'Verb Inflection': ['M:VERB:INFL', 'U:VERB:INFL', 'R:VERB:INFL'],
        'Word Order': ['M:WO', 'U:WO', 'R:WO'],
    };

    const generateColorShades = (color, count) => {
        const baseColor = tinycolor(color);
        return Array.from({ length: count }, (_, i) =>
            baseColor.clone().darken(i * 10).toString()
        );
    };

    const errors = data.flatMap(({ color, type }) => {
        const rule = rules[type];
        if (!rule) return []; // Ignore types that are not in the rules

        const shades = generateColorShades(color, rule.length);
        return rule.map((value, index) => ({ type: value, color: shades[index] }));
    });



    // Fonction pour mettre à jour le mot et le statut de l'erreur
    const reRenderWord = (word,type,corrected) => {
        if (type === 'correct' && !corrected) {
        console.log("reRenderWord:", word);
        setAppeared(word);
        setCorrected(true);
       }else if(type === 'correct' && corrected){
            setAppeared(initial);
            setCorrected(false);
        }
        setIsOpen(false);
        setStatus(type)
        getSentences()


    }

    // Fonction de gestion du changement d'ouverture du tooltip
    const onChange = useCallback((e) => {
        setIsOpen(e);
    }, []);

    // Effet pour initialiser l'état `appeared` au montage du composant
    useEffect(()=>{
        setAppeared(initial)
        setColor("")
        console.log("coloee ",type)
    },[])

    // Effet pour mettre à jour la couleur en fonction du type d'erreur
    useEffect(() => {
        
        const error = errors.find(error => error.type === type);
        if (error ) {
            if ( !corrected)
          {  setColor(error.color);}
            else {
                setColor("success");
            }

        } else{
            if ( !corrected)
                {  setColor("blue");}
                  else {
                      setColor("success");
                  }

        }

        console.log(color)

    }, [corrected]);


    const tooltipContent = (
        <div>
            <div style={{textAlign: 'right', cursor: 'pointer'}}>
                <CloseOutlined onClick={handleTooltipClose} style={{color: 'grey'}}/> {/* Use CloseOutlined directly */}
            </div>
            <div>
                <CardError
                    element={element}
                    phrases={phrases}
                    location={location}
                    color={color}
                    reRenderWord={reRenderWord}
                    corrected={corrected}
                />
            </div>
        </div>
    );
    const getColorBasedOnStatus = (status, corrected, color) => {
        if (status !== "correct") {
            return color;
        } else if (!corrected) {
            return color;
        } else {
            return "success"; // Adjust this to match your expected success color if needed
        }
    };
    const finalColor = getColorBasedOnStatus(status, corrected, color);
    const className =  `underline underline-${finalColor}` ;

    return (
        <Tooltip
            title={tooltipContent}
            color={'white'}
            onOpenChange={onChange}
            trigger={"click"}
            open={isOpen}
            className={'tooltip-text'}
            overlayInnerStyle={{width: '400px'}} // Adjust width and text color here
            placement="bottom"
            overlayClassName='open-tooltip'

        >
               <span
                   className={`underline underline-${color}`}
                   style={color === "success"?{}:{"text-decoration-color":color}}
               >
        {appeared}{" "}
      </span>
        </Tooltip>
    );
};

export default BlockElement;