import { render } from '@testing-library/react';
import React, {useState, useRef} from 'react';
import {Button, Input} from "antd";
import './CardError.css';
import '../../Pages/Correction/Correction.css';
import {SaveOutlined,CheckOutlined,DeleteOutlined,UndoOutlined} from "@ant-design/icons";


const CardInlineCorrection = (props) => {
    const {phrases,initial,location,element,color,corrected} = props;
    const inputRef = useRef(null);

    const [newValue,setNewValue] = useState("");
    const [correct,setCorrect] = useState(false);
    function  saveCorrection(){
            var value = inputRef.current.value;
            setNewValue(value);
            let type= 'add correction';
            if(!newValue){
                type = 'undo';
                setCorrect(false)
            }else{
                setCorrect(true)
            }
            props.reRenderWord(newValue,type,correct)
    }

    function onchangeValue(e){
        const value = e.target.value;
        setNewValue(value);
    }

    return(
        <div className={"cardError"}>
            <h5>Add your Correction</h5>
            <Input
                ref={inputRef}
                placeholder="Please enter your correction below"
                value={newValue}
                onChange={onchangeValue}
                style={{width: '100%'}}
                rootClassName={"input-text"}
            />
            <hr className={"hr"}/>
            <div className="button-container">
                <Button
                    type="primary"
                    icon={correct === false ?<CheckOutlined/>: <UndoOutlined />}
                    className="button button-big button-right"
                    onClick={()=>{ saveCorrection()} }
                >{correct === false ? 'Correct' : 'Undo correction'}</Button>
            </div>
        </div>
    )
};

export default CardInlineCorrection;