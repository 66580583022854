import React, { useState } from 'react';
import { Layout, Button, Menu } from 'antd';

import logo from '../../assets/images/logo.svg';
import Sidebar from "../../Components/sidebar/Sidebar";
import './AppLayout.css';
import {Image} from "antd";
import LeftSideBar from '../../Components/LeftSideBar/LeftSideBar';


const { Content, Header } = Layout;

const AppLayout = props => {

    const [collapsed, setCollapsed] = useState(false);

    const handlSidebarCollapse =()=>{
        setCollapsed(!collapsed)
      }
    console.log("props.children", props)

    return (
        <Layout style={{ minHeight: '100vh' }} className={"app-layout"}>
            {!props.hidesidebar && <LeftSideBar collapsed={collapsed} handlSidebarCollapse={handlSidebarCollapse} /> }
            <Content className={"layout"}>
                <div className={"main-content"}>
                    {!props.hideHeader &&
                        <Header className="header">
                            <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}>
                                <Menu.Item key="2">
                                    <Image
                                        width={140}
                                        src={logo}
                                        alt="Background"
                                        preview={false}
                                    />
                                </Menu.Item>
                            </Menu>
                        </Header>
                    }

                    <div className={""}>
                        {props.children}
                    </div>
                    {/*<div className={"sidebar-container"} >*/}
                    {/*    <Sidebar />*/}
                    {/*</div>*/}
                </div>
            </Content>
        </Layout>
    )
}

export default AppLayout

