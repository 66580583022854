import React, {useEffect, useState} from 'react'
import RubricScoresAccordion from "../../Components/accordion/rubricScores-accordion/RubricScoresAccordion";
import AssignmentAccordion from "../../Components/accordion/assignment-accordion/AssignmentAccordion";
import { InfoCircleOutlined, PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import AnalyticsProgress from "../../Components/analyticsProgress/AnalyticsProgress";
import exportfileIcon from "../../assets/images/exportFile-icon.svg";
import remediaIcon from "../../assets/images/remedia-icon.svg";
import outIcon from "../../assets/images/Out-icon.svg";
import "./submissionDetails.css";
import { Button } from 'antd';
import AppLayout from "../Layout/AppLayout";
import StatisticsModal from "../../Components/StatisticsModal/StatisticsModal";
import Tooltip from "antd/lib/tooltip";
import {useGetStudentSubmissionQuery,useSubmitOpenIAFeedbackMutation} from "../../services/studentsubmissions.api";
import {useParams} from "react-router-dom";
import html2pdf from "html2pdf.js";
import data from "../../Components/accordion/rubricScores-accordion/data";
import {Spinner} from "@chakra-ui/react";
import { useFormik } from 'formik';



const SubmissionDetails =  () => {
    const {id} = useParams();
    const {data: studentSubmission, error, isLoading} = useGetStudentSubmissionQuery(id);
    const [submitOpenIAFeedback] = useSubmitOpenIAFeedbackMutation();

    const [visible, setVisible] = useState(false);
    const [reponse, setReponse] = useState(false);
    const [reponseParse, setReponseParse] = useState(false);
    const [rubric, setRubric] = useState(false);
    const [feedbacks, setFeedbacks] = useState([]);
    const [feedbacksArray, setFeedbacksArray] = useState(null);

    const fetchData = async () => {
        if(studentSubmission){
            console.log('studentSubmission: ',studentSubmission)

            const  { data  , error } =   await submitOpenIAFeedback(
                {answer:studentSubmission.content,
                    assignement:studentSubmission.studentCourseWork.coursework.id
                    ,grade:studentSubmission.studentCourseWork.coursework.grade
                    ,rubricId: studentSubmission.studentCourseWork.coursework.rubric.id
                    ,submissionId:studentSubmission.id}
            )
            console.log("data",)
            if(data){
                setReponse(data)
                setReponseParse(data)
                console.log("data   IAAAA",data)
                try {
                    console.log("jsonData===>:::",reponse);
                } catch (error) {
                    console.error("Error parsing JSON:", error); // Handle the error here
                }
            }
            if(error){
                console.log("error  IAAAA",error)
            }
        }
    }
    useEffect( () => {
        console.log('data: ',reponse,"JSON.parse(data)====>",reponseParse)
        setFeedbacksArray(reponse.feedbacks)
        setFeedbacks(reponseParse.actionable_feedbacks)
        console.log('feedbacksArray',feedbacksArray,'actionableFeedbacks',feedbacks)
    }, [reponse]);
    useEffect( () => {

        fetchData()

    }, [studentSubmission]);


    useEffect(() => {
        if(studentSubmission && studentSubmission.studentCourseWork  && studentSubmission.studentCourseWork.coursework.rubric ) {
            setRubric(studentSubmission.studentCourseWork.coursework.rubric.title)
        }
    }, []);


    console.log('get studentSubmission',studentSubmission)
    const handleExport = (studentSubmission) => {
        const element = document.getElementById('left');
        const elementRight = document.getElementById('right');
        element.style.display = 'block';
        let title = '';
        let filename= 'CourseWork';
        if(studentSubmission && studentSubmission.studentCourseWork){
            let studentCourseWork = studentSubmission.studentCourseWork;
            let courseWork = studentCourseWork.coursework;
            title= courseWork.title;
        }
        if(title){
            filename= filename+'_'+ `${title}.pdf`;
        }
        const opt = {
            margin: [10, 10, 10, 10], // Marges : haut, gauche, bas, droite
            filename: filename, // Nom du fichier PDF généré
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 2, // Augmenter la résolution
                logging: true, // Activer le logging pour le débogage
                letterRendering: true, // Améliorer le rendu des lettres
                useCORS: true, // Pour charger les images externes
            },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }, // Format A4
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }, // Gestion des sauts de page
        };

        html2pdf()
            .from(element)
            .set(opt)
            .toPdf()
            .get('pdf')
            .then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages();
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() - 50, pdf.internal.pageSize.getHeight() - 10);
                }
            })
            .save()
        // meme chose pour le right
        html2pdf()
            .from(elementRight)
            .set(opt)
            .toPdf()
            .get('pdf')
            .then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages();
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(10);
                        pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() - 50, pdf.internal.pageSize.getHeight() - 10);
                    }
                }
            )
            .save()
    };

    const addFeedback = () => {
        setFeedbacks([...feedbacks, 'Add your feedback here ...']);
    };

    const removeFeedback = (index) => {
        const newFeedbacks = feedbacks.filter((_, i) => i !== index);
        setFeedbacks(newFeedbacks);
    };

        const handleChange = (index, event) => {
            console.log("event", event.target.value);
            const newFeedbacks = [...feedbacks];
            newFeedbacks[index] = {actionable_feedback
                : event.target.value};
            setFeedbacks(newFeedbacks);
        };
        const handleOpenModal = () => {
            setVisible(true); // Afficher la modal lors du clic sur le bouton
        };

    const handleCloseModal = () => {
        setVisible(false); // Fermer la modal
    };

    const [criteriaScore , setCriteriaScore] = useState([])
    const criteriaUsage = (criteriaIndex, score, content) => {

        const updatedCriteriaScore = feedbacksArray.map((criterion, cIndex) => {
            if (cIndex === criteriaIndex) {
                return {
                    ...criterion,
                    score: score,
                    scoreExplanation: content
                };
            }
            return criterion;
        });

        setCriteriaScore(updatedCriteriaScore);
    };

    return (
        <AppLayout hideHeader={true} >
            <div className="teacher__container">
                <h1 className="teacher__container-title">
                    <span>CourseWork</span>
                    <Tooltip title="This is your coursework" placement={"top"}>
                        <InfoCircleOutlined style={{fontSize: "10px"}}/>
                    </Tooltip>
                </h1>

                <div className="teacher__assignment" id={"teacher__assignment"}>
                    <div className="right" >
                        <AssignmentAccordion data={studentSubmission} updatedCriteriaScore={criteriaScore} updatedFeedBacks={feedbacks} />
                    </div>
                    <div className="left" >
                        <div id={'left'} >
                            <div className="critical__issues_container">
                                <div className="critical-issues">
                                    <h1 className="critical-issues__title">
                                        Critical Issues
                                    </h1>
                                    <Button className="critical-issues__button"
                                            onClick={handleOpenModal}
                                    >
                                        <img src={outIcon} alt="out icon"/>
                                        <span>View</span>
                                    </Button>
                                </div>
                                <div className="analytics">
                                    <AnalyticsProgress
                                        label="grammar mistake"
                                        percentage={46}
                                        progressColor="#FF3838"
                                    />
                                    <AnalyticsProgress
                                        label="spelling mistake"
                                        percentage={50}
                                        progressColor="#5A88FF"
                                    />
                                    <AnalyticsProgress
                                        label="lexic mistake"
                                        percentage={75}
                                        progressColor="#44DA85"
                                    />
                                    <AnalyticsProgress
                                        label="syntax mistake"
                                        percentage={100}
                                        progressColor="#FFCF00"
                                    />
                                </div>
                            </div>
                            <div className="rubric__score-container">
                                <div className="rubric__score-title">
                                    <h1>Rubric Scores</h1>
                                    <h5>{rubric}</h5>
                                </div>
                                <div className="rubric__usage">
                                    {
                                        feedbacksArray ?  feedbacksArray.map((item, index) =>
                                                item.score ? (
                                                    <RubricScoresAccordion
                                                        key={index}
                                                        index={index}
                                                        criteriaIndex={index}
                                                        criteriaTitle={item.criterion}
                                                        scores={item.score}
                                                        criteriaUsage={(criteriaIndex, score, content) => criteriaUsage(criteriaIndex, score, content)}
                                                        scoreExplanation={item.scoreExplanation}
                                                    />
                                                ) : null
                                            )
                                            : <Spinner/> }
                                </div>
                            </div>
                            <div className="feedback-container">
                                <div className="feedback__title">
                                    <h1>Feedback</h1>
                                    <Button className="addFeedback__button" onClick={addFeedback}>
                                        <PlusCircleOutlined/>
                                        <span>Add</span>
                                    </Button>
                                </div>
                                {feedbacks ? feedbacks.map((feedback, index) => (
                                    <div key={index} className="feedback__content">
                                        <span>{index + 1}.</span>
                                        <textarea
                                            className="feedback__content-text"
                                            value={feedback.actionable_feedback}
                                            onChange={(event) => handleChange(index, event)}
                                        />
                                        <CloseCircleOutlined className="close" onClick={() => removeFeedback(index)}/>
                                    </div>
                                )): <Spinner/> }
                            </div>
                        </div>
                        <Button className="corrections-btn">
                            <img src={remediaIcon} alt="remedia icon"/>
                            <span>Corrections</span>
                        </Button>
                        <Button className="exportFile-btn" onClick={()=> handleExport(studentSubmission)}>
                            <img src={exportfileIcon} alt="remedia icon"/>
                            <span>Export File</span>
                        </Button>
                    </div>
                </div>
            </div>
            <StatisticsModal visible={visible} handleCloseModal={handleCloseModal}/>
        </AppLayout>
    )

}

export default SubmissionDetails