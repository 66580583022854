import remediaLogo from "../../../assets/images/remedia-logo.png"
import {ArrowLeftOutlined, InfoCircleOutlined} from "@ant-design/icons"
import {Alert, Button} from "antd"
import "./verification.css"
import {useResentMutation} from "../../../services/resentEmail.api";
import {useState} from "react";

const Verification = ({user}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const [resentNotification] = useResentMutation();
    const handleSubmit  = async () =>{

        const { data, error: userError } = await resentNotification({email:user.email});

        if (data){
            setErrorMessage(null);
            setSuccessMessage('Email Sent Successfully');

        }
        if (userError){
            setSuccessMessage(null);
            setErrorMessage('An error occurred while sending email. Please try again or contact support!');
        }

    }
    return (
        <main>
            <img src={remediaLogo} alt="remedia logo" className="__remediaLogo"/>
            <div className="container">
                <div className="verification __box">

                    <h1 className="__title">Check your Email</h1>
                    <span className="verification__subTitle __mixSubTitle">
                        We sent a login email to {user.email}
                    </span>
                    {errorMessage && (
                        <Alert
                            message={errorMessage}
                            type="error"
                            icon={<InfoCircleOutlined />}
                            showIcon
                            className="__errorField"
                        />
                    )}
                    {successMessage && (
                        <Alert
                            message={successMessage}
                            type="success"
                            icon={<InfoCircleOutlined />}
                            showIcon
                            className="__errorField"
                        />
                    )}
                    <Button className="__authBtn" onClick={handleSubmit}>
                        Resend the verfication
                    </Button>
                    <a href="/sign-in" className="__backToSign-in">
                        <ArrowLeftOutlined />
                        <span>Back to sign in</span>
                    </a>
                </div>
            </div>
        </main>
    )
}

export default Verification
