import React from 'react';
import {Layout, Menu, Button, Card, Row, Col,message, Typography,Collapse,Image} from 'antd';
import {
    SaveOutlined,
    ExportOutlined
} from '@ant-design/icons';
import './Sidebar.css';
import IssuesBlock from '../IssuesBlock/IssuesBlock';
import Statistics from '../Statistics/Statistics';
import SideCorrection from '../SideCorrection/SideCorrection';
import Comment from '../Comment/Comment';
import logoBtn from '../../assets/images/logo-btn.svg';
import { correctionCall } from '../../services/correctionService.api';
import { useDispatch, useSelector } from 'react-redux';
import { setCorrection, setError, setIsCorrected, setLoading, setSlateText, setStateError } from '../../redux/app/app.slice';
import { selectCorrection, selectSlateText, selectTextBrut } from '../../redux/app/app.selectors';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;
const { Panel } = Collapse;

function formatTextToM2(text) {
    // Split the text into sentences based on the "S" tag
    const sentences = text.split(/(?=S\s)/).filter(Boolean);

    // Initialize an array to store formatted sentences
    let formated  = ""
    // Iterate over each sentence
    sentences.forEach(sentence => {
        // Split the sentence into its components (S and A tags)
        const components = sentence.split(/(?=A\s)/).filter(Boolean);
        
        // Extract the S tag (and remove it)
        const sTag = components.shift().trim().replace(/^S\s/, '');

        // Extract A tags and parse them into objects
      
       
        let newArr = components.map(str => str.replace(/^A\s*/, ''));

        formated = {type:"error",children:[{text:sTag,error:newArr}]}
       

        // Push the formatted sentence object into the array
        
    });

    return formated;
}
function highlightErrors(m2) {
    const split = m2.split(/\n(?=S\s)/);

    const originalSentences = [];
    let offset = 0;

    console.log("split",split)

    for (const sent of split) {
        let sentence = formatTextToM2(sent)
        originalSentences.push(sentence)
    }
      
    return originalSentences;
}

function errorsState(m2) {
    const split = m2.split(/\n(?=S\s)/);

    const originalSentences = [];
    let offset = 0;

    console.log("split",split)

    for (const sent of split) {
        let sentence = formatTextToM2(sent)
        originalSentences.push({"phrase":sentence,"corrected":false})
    }
      
    return originalSentences;
}
const App = () => {


    const dispatch = useDispatch()
    const data = useSelector(selectTextBrut)
    console.log("selector",data)
    
    const handleButtonClick = () => {
        message.success("Changes saved");
    };
    const handleCorrection =() =>{

        dispatch(setLoading(true));

        correctionCall(data).then((data) => {
            dispatch(setLoading(false));

            data = data["data"]
            console.log("highlightErrors",highlightErrors(data["content"]))

            console.log('data axioss',data["content"])
            dispatch(setStateError(errorsState(data["content"])))
            dispatch(setSlateText(highlightErrors(data["content"])))
            dispatch(setIsCorrected(true))
            dispatch(setCorrection({"num_errors":data["num_errors"],"num_words":data["num_words"],"res":data["res"],"verb_form":data["verb_form"],"verb_form_ratio":data["verb_form_ratio"],"verb_tense":data["verb_tense"],"verb_tense_ratio":data["verb_tense_ratio"],"error_composition":data["error_composition"]}));

          })
          .catch(() => {
            dispatch(setLoading(false));
            dispatch(setError("error get correction from the server"))

            console.log("error get correction axios");
          

          })


    }
    return (
        <Sider className="site-layout site-sider">
            <Menu
                mode="inline"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                style={{ height: '100%', borderRight: 0 ,backgroundColor: 'transparent'}}
            >
                <Card title="" bordered={false} style={{width: "100%",backgroundColor: 'transparent'}} className={"main-card"}>

                    <IssuesBlock/>
                    <Statistics/>
                    <SideCorrection/>
                    <Comment/>
                    <Button type="primary" block
                            style={{marginLeft: 8, float: 'right', marginTop: 6, marginBottom: 10 }} onClick={handleCorrection} className={"button button-big btn-correction"}>
                        <Image width={20} src={logoBtn} alt="Background"  preview={false}/>
                        Correction
                    </Button>
                    <div style={{marginTop: 6}}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Button
                                    onClick={handleButtonClick}
                                    type="default"
                                    icon={<SaveOutlined/>}
                                    style={{width: '100%'}}
                                    className="button button-secondary button-big btn-normal"
                                >
                                    Save changes
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button
                                    type="default"
                                    icon={<ExportOutlined />}
                                    style={{width: '100%'}}
                                    className="button button-secondary button-big btn-normal"
                                >
                                    Export File
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </Menu>
        </Sider>
    );
};

export default App;