import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import { configureAppStore } from './redux/store';
import * as React from 'react';
import Root from "./routes/Root";
import {Spin} from "antd";
import './index.css';

const { store, persistor } = configureAppStore();

const root = ReactDOM.createRoot(document.getElementById('root'));

const Skeleton = () => {
    return (
        <div className={"loading"}>
            <Spin className={"loading__spin"} />;
        </div>
    )
};

root.render(
    <Provider store={store}>
        <PersistGate loading={<Skeleton />} persistor={persistor}>
            <HelmetProvider>
                <Root />
            </HelmetProvider>
        </PersistGate>
    </Provider>
);
