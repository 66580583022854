import React, {useState, useContext, useEffect} from 'react';
import { Layout, Menu, Button, Card, Input, message, Upload,Image,Modal } from 'antd';
import { UploadOutlined, FileDoneOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import SlateEditor from '../../Components/SlateEditor/SlateEditor';
import Sidebar from "../../Components/sidebar/Sidebar";
import './Correction.css';
import logo from '../../assets/images/logo.svg';
import correction from '../../assets/images/correction.svg'
import TextEditor from "../../Components/SlateEditor/TextEditor";
import {useSelector} from "react-redux";
import {selectSlateText} from "../../redux/app/app.selectors";
import {selectIsCorrected} from "../../redux/app/app.selectors";
import { Link } from 'react-router-dom';
import AppLayout from "../Layout/AppLayout";



const { Header, Content } = Layout;

const Correction = () => {
    const [inputValue, setInputValue] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const dataRef = useSelector(selectSlateText)
    const isCorrected = useSelector(selectIsCorrected)



    let errorsTab = []
    const filteredErrors = dataRef.filter(item => {
        const filteredChildren = item.children.filter(child => {
            const childError = item
            const children = childError.children;
            children.map(child => {
                if(child.error){
                    console.log("error", child.error,child.error.length)
                    let er = child.error[0]
                    if(!er.startsWith('-1')){
                        console.log("er[i]", er)
                        errorsTab.push(er)
                    }
                }
            })
        })
        return errorsTab
    });
    console.log("filteredErrors--1-------------------",errorsTab)
    console.log("isCorrected---------------------",isCorrected)
    const handleButtonClick = () => {
        setModalVisible(true);
    };

    const handleModalOk = () => {
        setModalVisible(false);
    };

    const handleModalCancel = () => {
        setModalVisible(false);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };


    const handleUploadChange = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    return (
        <AppLayout hidesidebar={true}>
            <div className={"app-correction"}>
                <div className='editor-conrainer'>
                    <div className={"header-container"}>
                        <div className={"right-content-header"}>
                            <div>
                                <label className={"label"}>File
                                    name</label><ExclamationCircleOutlined/><br/>
                                <Input
                                    placeholder="Subject name"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    style={{width: 500, height: 40}}
                                    rootClassName={"input-text"}
                                />
                            </div>
                            {isCorrected &&
                                <div className={"mistakes-corrected"}>
                                    <img src={correction}/>
                                    <div className={"nbr-mistackes"}>{errorsTab.length} mistake(s) corrected</div>
                                </div>
                            }
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <Button type="default" onClick={handleButtonClick} icon={<FileDoneOutlined/>}
                                    style={{marginRight: 8}}
                                    className={"button button-secondary button-big btn-correct"}>
                                Final mark
                            </Button>
                            <Upload
                                name="file"
                                action="/upload.do"  // Remplacez par l'URL de votre endpoint de téléchargement
                                onChange={handleUploadChange}
                                showUploadList={false}
                            >
                                <Button icon={<UploadOutlined/>} type={'primary'}
                                        className={"button button-big btn-upload"}>New File</Button>
                            </Upload>
                        </div>
                    </div>
                    <Card title="" bordered={false} className={"card-editor"}>
                        <TextEditor/>
                    </Card>
                </div>
                <div className={"sidebar-container"}>
                    <Sidebar/>
                </div>
            </div>
            <Modal
                title="Final Mark"
                visible={modalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
            >
                <p>Enter a final mark</p>
                <Input placeholder="N/20" value={inputValue} onChange={handleInputChange}/>

            </Modal>
        </AppLayout>


    );
};

export default Correction;