import React, {useEffect, useState, useCallback, useContext} from 'react';
import { Tooltip } from "antd";
import CardError from './CardError';
import './BlockElement.css';
import {string} from "slate";
import CardInlineCorrection from "./CardInlineCorrection";
import {CloseOutlined} from "@ant-design/icons";

const BlockInlineCorrection = (props) => {
    const [appeared, setAppeared] = useState("");
    const [newWord, setNewWord] = useState("");
    const [corrected,setCorrected] = useState(false);
    const [isOpen,setIsOpen] = useState(false);
    const [status,setStatus] = useState("error");
    const {phrases,initial,location} = props;

    const handleTooltipClose = () => {
        setIsOpen(false); // Close the tooltip
    };

    // set words avec les mots de la phrase
    useEffect(()=>{
        setAppeared(initial)
    },[])
    const reRenderWord = (word,type,corrected) => {
            if(word){
                setAppeared(word);
                setNewWord(word);
            }
            else if('undo' === type) {
                setAppeared(initial);
                setNewWord(initial);
            }
            setIsOpen(false);
            setStatus(type)
            setCorrected(corrected);
    }

    const onChange = useCallback((e) => {
        setIsOpen(e);
    }, []);


    // Effet pour mettre à jour la couleur en fonction du type d'erreur
    useEffect(() => {
    }, []);

    const tooltipContent = (
        <div>
            <div style={{textAlign: 'right', cursor: 'pointer'}}>
                <CloseOutlined onClick={handleTooltipClose} style={{color: 'grey'}}/> {/* Use CloseOutlined directly */}
            </div>
            <div>
                <CardInlineCorrection
                    phrases={phrases}
                    location={location}
                    initial={initial}
                    reRenderWord={reRenderWord}
                    corrected={corrected}
                />
            </div>
        </div>
    );


    return (
        <Tooltip
            title={tooltipContent}
            color={'white'}
            onOpenChange={onChange}
            trigger={"click"}
            open={isOpen}
            className={'tooltip-text-correction'}
            overlayInnerStyle={{width: '400px', marginLeft: "20px"}} // Adjust width and text color here
            placement="bottom"
            overlayClassName='open-tooltip'
        >
            <span
                className={`${newWord && initial !== newWord  ? `text-add-correction`  : ""}`}> {appeared} </span>

        </Tooltip>
    );
};

export default BlockInlineCorrection;