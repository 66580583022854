import { api } from './api';
export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (data) => ({
                url: 'api/register',
                method: 'POST',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/json' },
            }),
            invalidatesTags: [{ type: 'Register', id: 'TOKEN' }],
        }),
    }),
});

export const { useRegisterMutation } = authApi;