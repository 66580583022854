import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  error: null,
  loading: false,
  notification: null,
  slateText: [{
    type: 'paragraph',
    children: [
      { text: ' ' },
    ]
  }],
  isCorrected: false,
  stateError:[],
  textBrut: "",
  correction:{
    num_errors: [],
    num_words: 0,
    res: {},
    verb_form: 0,
    verb_form_ratio: 0,
    verb_tense: 0,
    verb_tense_ratio: 0,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCorrection: (state, action) => {
      state.correction = action.payload;
    },
    setSlateText: (state, action) => {
      state.slateText = action.payload;
    },
    setIsCorrected: (state, action) => {
      state.isCorrected = action.payload;
    },
    setTextBrut: (state, action) => {
      state.textBrut = action.payload;
    },
    setStateError: (state, action) => {
      state.stateError = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload.error;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading, setError, setNotification, setCorrection ,setIsCorrected, setStateError, setSlateText, setTextBrut } = appSlice.actions;
export const { name: sliceKey } = appSlice;

export default appSlice.reducer;
