import {AppstoreAddOutlined, ProjectOutlined, SnippetsOutlined} from "@ant-design/icons";
import './leftSideMenu.css';
import {NavLink} from "react-router-dom";
import {useGetMeQuery} from "../../services/auth.api";

const LeftSideMenu = ({collapsed}) => {
    const { data: authenticatedUser, error, isLoading } = useGetMeQuery();
    let isTeacher = false;
if(authenticatedUser){
    const roles = authenticatedUser.roles
    // check if the user is a teacher
     isTeacher = roles.includes('ROLE_TEACHER');
}
    console.log('collapsed', collapsed);
    return (
        <div className="list">
            <NavLink caseSensitive to="/">
                <button
                    type="button"
                    className={`list-btn ${ collapsed ? `collapsed`:``}`}
                >
                    {
                        collapsed
                            ?
                            <SnippetsOutlined className="icon"/>
                            :
                            <>
                                <SnippetsOutlined />
                                <span>CourseWork</span>
                            </>
                    }
                </button>
            </NavLink>
            {isTeacher && (
                <>
                <NavLink caseSensitive to="/rubric">
                <button
                    type="button"
                    className={`list-btn ${collapsed ? `collapsed`:``}`}
                >
                    {
                        collapsed
                            ?
                            <ProjectOutlined style={{rotate:"-90deg"}} className="icon"/>
                            :
                            <>
                                <ProjectOutlined style={{rotate:"-90deg"}} />
                                <span>Rubric</span>
                            </>
                    }
                </button>
            </NavLink>

            <NavLink caseSensitive to="/submissions">
                <button
                    type="button"
                    className={` list-btn ${collapsed ? `collapsed`:``}`}
                >
                    {
                        collapsed
                            ?
                            <AppstoreAddOutlined className="icon" />
                            :
                            <>
                                <AppstoreAddOutlined />
                                <span>Submissions</span>
                            </>

                    }
                </button>
            </NavLink>
                </>
            )}
        </div>
    );
}

export default LeftSideMenu