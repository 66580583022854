import AppLayout from "../Layout/AppLayout";
import Table from "../../Components/table/Table";
import "./Assignment.css"
import React, {useEffect, useState} from "react"
import {Button, Dropdown, Input, Spin} from "antd";
import Filter from "../../assets/images/filter.svg";
import {SearchOutlined,FormOutlined, DeleteOutlined} from "@ant-design/icons";
import NewAssignment from "../../Components/newAssignment/NewAssignment";
import Code from "../../assets/images/code.svg";
import {useNavigate} from "react-router-dom";
import {useLazyGetAssignmentsQuery} from "../../services/assignments.api";
import {useLazyGetCheckStudentcourseWorkQuery} from "../../services/studentcourseworks.api";
import moment from 'moment';
import {useGetMeQuery} from "../../services/auth.api";
import EditAssignment from "../../Components/editAssignment/EditAssignment";
import {useLazyGetCourseWorkQuery, useDeleteCourseWorkMutation} from "../../services/coursworks.api";
import { Modal, message } from 'antd';

const Remedia = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { data: authenticatedUser, error, isLoading } = useGetMeQuery();
  let isTeacher = false;
  if(authenticatedUser){
    const roles = authenticatedUser.roles
    isTeacher = roles.includes('ROLE_TEACHER');
  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const [triggerAssignment, {data: assignmentData, isLoading:isLoadingAssignments }] = useLazyGetAssignmentsQuery();
  const [studentId, setStudentId] = useState(null);
  const [courseId, setCourseId] = useState(null);
  const [coursWorkData, setCourseWorkData] = useState(null);
  const [trigger] = useLazyGetCheckStudentcourseWorkQuery();
  const [triggerCourseWork, { dataCourseWork, error: errorCourseWork, isLoading: isLoadingCourseWork }] = useLazyGetCourseWorkQuery();
  const [deleteCourseWork] = useDeleteCourseWorkMutation();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    triggerAssignment()
  }, [triggerAssignment]);



  const handlSidebarCollapse =()=>{
    setCollapsed(!collapsed)
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const addAssisement = () => {
    setIsModalOpen(!isModalOpen);
  };

  const editAssisement = (item) => {

    console.log('item to update ::', item.id);
    triggerCourseWork(item.id).then((result) => {
      if (result.data) {
        console.log('data lllll::', result.data);
        setCourseWorkData(result.data);
        setIsEditModalOpen(!isEditModalOpen);
      }
    });
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteAssisement = (item) => {
    console.log('item to delete ::', item.id);
    const { confirm } = Modal;
    confirm({
      title: 'Are you sure you want to delete this assignment?',
      content: `Assignment ID: ${item.id}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('Item to delete ::', item.id);
        deleteCourseWork({ assignmentId: item.id }).then((result) => {
          if (result.data) {
            console.log('Deleted data ::', result.data);
            triggerAssignment();
            message.success('Assignment deleted successfully');
          }
        });
      },
      onCancel() {
        console.log('Deletion canceled');
      },
    });
  }

  const [checkedItems, setCheckedItems] = useState([]);


  const formatDate = (text) => {
    console.log('text+++++++++++++++', text);
    if (!text) {
      console.log('No date provided');
      return '';
    }
    return moment(text).format('YYYY-MM-DD');
  };

  const timeConverter = (rawDate) => {
    return moment(rawDate).format("MMM DD YYYY");
  }


  const columns = [
    { key: 1 , title: '', dataIndex: 'id'},
    { key: 2 , title: 'CourseWork Title', dataIndex: 'title'},
    { key: 3 , title: 'Format', dataIndex: 'type'},
    { key: 4 , title: 'Subject', dataIndex: 'subject' },
    { key: 5 , title: 'description', dataIndex: 'description'},
    { key: 6 , title: 'Grade', dataIndex: 'grade'},
    {
      key: 7,
      title: 'Created Date',
      dataIndex: 'created_at',
      // render: (text) => formatDate(text),
      render: (created_at) => timeConverter(created_at),

    },
    { key: 8 , title: 'State', dataIndex: 'state'},
    { key: 9 , title: 'Action', dataIndex: 'Action'},
  ];

  const handleCheckboxChange = (e, key) => {
    const { checked } = e.target;
    setCheckedItems((prev) =>
        checked ? [...prev, key] : prev.filter((item) => item !== key)
    );
  };
  const navigate = useNavigate();

  const handleActionClick = async (id) => {
    if(!isTeacher){
      setStudentId(authenticatedUser.id);
      setCourseId(id);
      const {data, error } = await trigger({ student: authenticatedUser.id, course: id });
      if(data){
        console.log('data =>', data);
          navigate(`/courseworks/${data.id}`);
      }
    }
  };
  const TableHeadCell = (column) => {
    if (column.dataIndex === 'id' || column.dataIndex === 'Action') {
      return column.title;
    }
    else {
      return (
          <>
            <span>{column.title}</span>
            <img src={Code} alt="code icon" width={16} />
          </>
      );
    }
  };

  const TableBodyCell = (column, item) => {
    switch (column.dataIndex) {
      case 'Action':
        return (
            isTeacher ? (
                <div style={{display: "flex", alignItems: "center"}}>
                  <Button onClick={() => editAssisement(item)} className='tableBody__cell-openBtn' icon={<FormOutlined />}>
                </Button>

                  <Button onClick={() => deleteAssisement(item)} className='tableBody__cell-openBtn' icon={<DeleteOutlined />}>
                  </Button>
                </div>

            ) : (
                <Button onClick={() => handleActionClick(item.id)} className='tableBody__cell-openBtn'>
                  open
                </Button>
            )
        );

      case 'type':
        return <span className='tableBody__cell-formatCol'>{item[column.dataIndex]}</span>;

      default:
        return item[column.dataIndex];
    }
  };


  return (
      <AppLayout hideHeader={true}>
        <div className="content__assignments">
          <div className={"assignment__top-page"}>
            <div className="assignments__filter">
              <span> CourseWorks</span>
            </div>

            <div className="assignments-new">
              <Input
                  type="text"
                  placeholder="search"
                  prefix={<SearchOutlined />}
                  value={searchTerm}
                  onChange={handleSearchChange}
              />
              {isTeacher && (
              <Button color="#FDD832" onClick={addAssisement}>New CourseWork</Button>
                  )}
              </div>
          </div>

          <NewAssignment isModalOpen={isModalOpen} onClose={() => {setIsModalOpen(false)}} />
          <EditAssignment isModalOpen={isEditModalOpen} coursWorkData={coursWorkData} onClose={() => {setIsEditModalOpen(false)}} />

          {
            // isLoadingAssignments ?
            //     <div className={"data__loading"}>
            //       <Spin className={"data__loading__spin"} size={"large"}/>;
            //     </div>
                // :
                <Table
                    columns={columns}
                    data={assignmentData ? assignmentData['hydra:member'] : []}
                    TableHeadCell={TableHeadCell}
                    TableBodyCell={TableBodyCell}
                    noDataText={`You haven't added any assignments yet.`}
                    searchTerm={searchTerm}
                    isLoading = {isLoadingAssignments ?? false}
                    page={currentPage}
                    onPageChange={handlePageChange}
                />
          }


        </div>
      </AppLayout>
  )
}

export default Remedia