import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Card, Typography, Table, Progress,Tag } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';
import { LineChart } from '@mui/x-charts';
import '../../App.css'
import './StatisticsModal.css';
import { Pie } from '@ant-design/charts';
import PieChart from "./PieChartData";
import {selectCorrection, selectIsCorrected} from "../../redux/app/app.selectors";
import {useSelector} from "react-redux"; // Assuming you have a CSS file for custom styles

const { Text } = Typography;

const StatisticsModal = ({ visible, handleCloseModal }) => {
    const [visibled, setVisibled] = useState(false);
    const correction = useSelector(selectCorrection);
    const isCorrected = useSelector(selectIsCorrected);
    const [chartOptions, setChartOptions] = useState(null);
    let errorTypes=[]
    let wordTypes=[]
    let num_words = 0
    let totalErrors= 0
    let ratioPaper = 0
    if(correction && correction.num_words){
        num_words = correction.num_words
    }
    if(correction && correction.error_composition && correction.error_composition.length>0){
      errorTypes = correction.error_composition[0]
      wordTypes = correction.error_composition[1]
     totalErrors = Object.values(errorTypes).reduce((a, b) => a + b, 0);
        ratioPaper= ((totalErrors / num_words) * 100);
        if(ratioPaper > 0){
            ratioPaper=ratioPaper.toFixed(2)
        }
    }


    const ratioCalculate = (ratio, value) => {
        return  (ratio*value).toFixed(2)
    };

    useEffect(() => {
        setVisibled(visible);
        if(visible){
            setChartOptions({
                series: [{
                    name: 'Ratio',
                    data: [ratioCalculate(ratioPaper,1.8), ratioCalculate(ratioPaper,1.7), ratioCalculate(ratioPaper,1.6), ratioPaper]
                }],
                options: {
                    chart: {
                        type: 'line',
                        height: 350,
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    title: {
                        text: 'Overall improvement'
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                    },
                    colors: ['#000095'],
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return val.toFixed(2);
                            }
                        }
                    },
                    interaction: {
                        zoomPan: false, // Désactive le zoom et le panoramique
                        zoomWheel: false, // Désactive le zoom à la molette
                        zoomKey: false, // Désactive le zoom par la touche Shift
                        reset: false, // Désactive le bouton de réinitialisation
                        resetOnContextMenu: false, // Désactive la réinitialisation sur le clic droit
                        homeButton: false,
                    }
                }
            })
        }
    }, [visible]);
    const getErrorPercentage = (type) => {
        return errorTypes[type] || 0; // Return the value from errorTypes or 0 if not found
    };

    const getWordPercentage = (category) => {
        return wordTypes[category] || 0; // Return the value from errorTypes or 0 if not found
    }
    const getColorWord = (category) => {
        return tableWordData.find((item) => item.category === category).color;
    }
    const colorsErrors = [
        '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    ];
    const data = [
        { color: '#FF6633', type: 'Adjective'},
        { color: '#E6B333',  type: 'Adjective form' },
        { color: '#80B300',  type: 'Adverb' },
        { color: '#FF99E6', type: 'Conjunction' },
        { color: '#66994D', type: 'Contraction' },
        { color: '#66664D',  type: 'Determiner' },
        { color: '#FFB399', type: 'Morphology' },
        { color: '#3366E6',  type: 'Noun' },
        { color: '#809900',  type: 'Noun Number' },
        { color: '#CCFF1A', type: 'Noun Possessive' },
        { color: '#B366CC',  type: 'Noun inflection' },
        { color: '#559f97', type: 'Orthography' },
        { color: '#a84b4b',  type: 'Other' },
        { color: '#999966', type: 'Particle' },
        { color: '#E6B3B3',  type: 'Preposition' },
        { color: '#FF1A66',  type: 'Pronoun' },
        { color: '#4D8000',  type: 'Punctuation' },
        { color: '#E666FF',  type: 'Spelling' },
        { color: '#adad46',  type: 'Subject-Verb Agreement' },
        { color: '#99FF99',  type: 'Unknown' },
        { color: '#6680B3',  type: 'Verb' },
        { color: '#E6331A',  type: 'Verb Form' },
        { color: '#B33300',  type: 'Verb Inflection' },
        { color: '#4DB3FF',  type: 'Verb Tense' },
        { color: '#b0ae3c',type: 'Word Order' },
    ];


    const createErrorRateTag = (rate, color) => {
        let percentage = ((rate / num_words) * 100);
        let fixedPercentage=percentage
        if(fixedPercentage > 0){
            fixedPercentage=fixedPercentage.toFixed(2)
        }
        return <Tag color={color}>{`${fixedPercentage}%`}</Tag>;
    };

    const tableWordData = [
        {color: '#FF0000', category: 'ADJ'},
        {color: '#44DA85', category: 'ADP'},
        {color: '#FFC0CB', category: 'ADV'},
        {color: '#800080', category: 'CCONJ'},
        {color: '#FFA500', category: 'DET'},
        {color: '#00B3E6', category: 'NOUN'},
        {color: '#991AFF', category: 'NUM'},
        {color: '#B33300', category: 'PART'},
        {color: '#809900', category: 'PRON'},
        {color: '#6680B3', category: 'PROPN'},
        {color: '#E666FF', category: 'PUNCT'},
        {color: '#FF33FF', category: 'VERB'},
        ]


    const getErrorTypeAndColor = (category) => {
        const item = data.find(d => d.type === category);
        return item ? { errorType: item.type, color: item.color } : { errorType: 'Unknown', color: '#000000' };
    };
    const initialTableData = data.map((item, index) => {
        const errorCount = errorTypes[item.type] || 0;
        return {
            key: index + 1,
            category: item.type,
            errorRate: createErrorRateTag(errorCount, item.color),
        };
    });

    const columns = [
        { title: 'Error Type', dataIndex: 'category', key: 'category' },
        { title: 'Error Rate', dataIndex: 'errorRate', key: 'errorRate' },
    ];
    const chartData = {
        series: [
            {
                data: [
                    { x: 'Jan', y: ratioCalculate(ratioPaper,1.8) },
                    { x: 'Feb', y: ratioCalculate(ratioPaper,1.7) },
                    { x: 'Mar', y: ratioCalculate(ratioPaper,1.6) },
                    { x: 'Apr', y: ratioPaper },
                    // { x: 'May', y: 90 },
                    // { x: 'Jun', y: 100 },
                    // { x: 'Jul', y: 110 },
                    // { x: 'Aug', y: 120 },
                    // { x: 'Sep', y: 130 },
                    // { x: 'Oct', y: 140 },
                    // { x: 'Nov', y: 90 },
                    // { x: 'Dec', y: 160 },
                ],
            },
        ],
        options: {
            // xAxes: [{ type: 'category', label: 'Month', categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'] }],
            xAxes: [{ type: 'category', label: 'Month', categories: ['Jan', 'Feb', 'Mar', 'Apr'] }],
            yAxes: [{ type: 'number', label: 'Data' }],
        },
    };
console.log('ratio cal',ratioPaper)


    return (

        <Modal
            title="Global Statistics"
            visible={visibled}
            onCancel={handleCloseModal}
            className="modal-statistics"
            footer={[
                <Button key="back" onClick={handleCloseModal}>
                    Close
                </Button>,
                <Button key="export" type="primary" icon={<BarChartOutlined />}>
                    Export Statistics
                </Button>
            ]}
            width="100%"
            style={{ top: 0 }}
            bodyStyle={{ height: 'calc(100vh - 55px)', overflow: 'auto', padding: '20px' }}
        >
            <div className="content-wrapper">
                <Card className="statistics-card-circles">
                    <div>
                        <h5 className={"title-statistics-bloc"}>Error count by type</h5>
                    </div>
                    <div className="statistics-circles">
                        {data.map((item, index) => (
                            <div key={index} className="stat-card">
                                <div className="progress-wrapper">
                                    <Progress type="circle" percent={getErrorPercentage(item.type)} size={40}
                                              strokeColor={item.color} format={(percent) => `${percent}`}/>
                                </div>
                                <div className="text-wrapper">
                                    <div className="circle-title">{item.type}</div>
                                    <div className="circle-text">{item.text}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Card>
                <Card className="statistics-card">
                    <div>
                        <h5 className={"title-statistics-bloc"}>Error ratio by type</h5>
                    </div>
                    <div className="statistics-table">
                        <Table className={"table-errors"} dataSource={initialTableData} columns={columns}
                               pagination={false}/>
                    </div>
                </Card>
            </div>
            <div className=" statistics-word-error" style={{marginTop: 20}}>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <Card>
                            < h5 className={"title-statistics-bloc"} > Text composition < /h5>
                            <PieChart/>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className={"chart-line"}>
                            {chartOptions && <Chart options={chartOptions.options} series={chartOptions.series} type="line"
                                    height={350}/>}
                        </Card>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default StatisticsModal;