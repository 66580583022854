import { api } from './api';

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/authentication_token',
        method: 'POST',
        body: JSON.stringify(credentials),
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: [{ type: 'Auth', id: 'TOKEN' }],
    }),
    getMe: builder.query({
      query: () => ({
        url: '/users/me',
        method: 'GET',
      }),
      providesTags: (result) =>
        result === undefined
          ? []
          : [
              { type: 'Auth', id: 'ME' },
              { type: 'Auth', id: result ? result.id : '' },
            ],
    }),
  }),
});

export const { useLoginMutation, useGetMeQuery, useLazyGetMeQuery } = authApi;