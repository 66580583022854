import {DoubleRightOutlined, FileAddOutlined, InfoCircleOutlined} from "@ant-design/icons";
import AssignmentImage from "../../../assets/images/assignment-image.svg";
import "./StudentAssignmentAccordion.css";
import React, {useEffect, useMemo, useState} from "react";
import Tooltip from "antd/lib/tooltip";
import {Alert, Button, Image, notification} from "antd";
import logoBtn from "../../../assets/images/logo-btn.svg";
import {Editable, Slate, withReact} from "slate-react";
import {createEditor, Node} from "slate";
import {
  useCreateStudentSubmissionMutation, useEditStudentSubmissionContentMutation,
} from "../../../services/studentSubmission.api";
import {
  useUploadHandwritingMutation
} from "../../../services/studentcourseworks.api";
import {ErrorMessage} from "formik";
import Swal from 'sweetalert2'; // Import SweetAlert2
import pdfToText from 'react-pdftotext'
import mammoth from 'mammoth';
import { Document, Packer, Paragraph, TextRun } from 'docx';


const resetEditor = (editor, nodes) => {
  const { selection } = editor;


  // Remove all nodes currently in the editor
  editor.removeNodes({ at: { anchor: editor.start([]), focus: editor.end([]) }});

  // Insert new nodes if `nodes` parameter is provided
  if(nodes) editor.insertNodes(Node.isNode(nodes) ? [nodes] : nodes);

  // Restore the previous selection or set it to the end of the editor

};


const StudentAssignmentAccordion = ({assignment}) => {
  const [accordionStates, setAccordionStates] = useState({});
  const [editor] = useState(() => withReact(createEditor()))
  // Use our deserializing function to read the data from Local Storage.
  const [selectedImage, setSelectedImage] = useState();

  const [createStudentSub] = useCreateStudentSubmissionMutation()
  const [uploadHandwritingStudentCourseWork] = useUploadHandwritingMutation()
  const [editStudentSub] = useEditStudentSubmissionContentMutation()


  const [error,setError] = useState(null)
  const [submissionState,setSubmissionState] = useState(null)
  const [api, contextHolder] = notification.useNotification();

  const [answer, setAnswer] = useState([
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ])
  const [submission,setSubmission] = useState(null)
  const [image,setImage] = useState(null)
  const extractText = async (event) => {
    const file = event.target.files[0];
    const type= file.type
    console.log('file tt',type)
    console.log('file file tt',file)
    // check if file is word
    if (type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || type === "application/msword"){
      try {
        extractTextFromWord(file).then(text => {
          const slateContent = [{type: "paragraph", children: [{text}]}];
          resetEditor(editor, slateContent)
          setAnswer(slateContent)
          console.log("Extracted text www:", text);
          console.log("Updated Slate content ww:", slateContent);
        });
      }catch (error) {
        console.error("Failed to extract text from Word:", error);
      }
    }
    else if (type === "application/pdf") {
      console.log('file TYPE', file.type)
      try {
        let text = await pdfToText(file);
        // check if text start with "Page 1 of"
        if (text.startsWith("Page 1 of")) {
            text=""
        }
        const slateContent = [{type: "paragraph", children: [{text}]}];
        resetEditor(editor, slateContent)
        setAnswer(slateContent)
        console.log("Extracted text:", text);
        console.log("Updated Slate content:", slateContent);

      } catch (error) {
        console.error("Failed to extract text from PDF:", error);
      }
    }
    else{
      alert('Please upload a word or pdf file')
      const formData = new FormData();
      setSelectedImage(file);
      formData.append('file', file); // `file` is the file you want to upload
      formData.append('studentworkId', assignment.id); // Any other data you want to send

      const { data, error } = await uploadHandwritingStudentCourseWork(formData);
      if(data){
        console.log('data+++++',data.message)
        const message = data.message;
        const slateContent = [{type: "paragraph", children: [{message}]}];
        console.log('data slateContent+++++',slateContent)
        //resetEditor(editor, slateContent)
        //setAnswer(slateContent)
      }

      if (error) {
        console.error('Upload failed:', error);
      } else {
        console.log('Upload successful:', data);
      }
    }
    };
  const extractTextFromWord = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      if (!arrayBuffer) {
        throw new Error('Failed to read file as ArrayBuffer');
      }

      const { value: textContent } = await mammoth.extractRawText({ arrayBuffer });
      console.log('text__',textContent)
      return textContent;
    } catch (error) {
      console.error("Error extracting text from Word file:", error);
      throw error; // Optionally rethrow the error if you want it to be caught by outer try/catch
    }
  };

  // const extractTextFromWord = async (file) => {
  //   try {
  //     const arrayBuffer = await file.arrayBuffer();
  //     const doc = new Document(arrayBuffer);
  //
  //     let textContent = '';
  //     doc.getParagraphs().forEach(paragraph => {
  //       textContent += paragraph.getText() + '\n';
  //     });
  //     console.log('text__',textContent)
  //     return textContent;
  //   }catch (error) {
  //     console.error("Error extracting text from Word file:", error);
  //     throw error; // Optionally rethrow the error if you want it to be caught by outer try/catch
  //   }
  // };

  useEffect(() => {
    if (assignment &&  assignment.studentSubmission)
    {
      resetEditor(editor,assignment.studentSubmission.content)
      setSubmissionState(assignment.studentSubmission.state)

    }
         if(assignment && assignment.coursework && assignment.coursework.image){
           setImage(assignment.coursework.image.filePath)
         }
  }, [assignment]);
  console.log('assignment++++++++++++++++',assignment)
  console.log('submitted++++++++++++++++',submissionState)
  const handleSubmit = async () =>{
    //  data id  change with student coourse work id
    console.log("onclick",assignment.id )
    // handle submit , value answer
    if (!assignment.studentSubmission){
      const  { data  , error } = await createStudentSub({content:answer,state:"submitted",studentCourseWork:"/api/student-course-works/"+assignment.id})
      console.log('assignment.studentSubmission data,',data,"submissionState")

      if (data){
        setSubmission(data)
      if(data.state==="submitted"){
       return Swal.fire({
         icon: 'success',
         title: 'Success!',
         text: 'Thank you for submitting your coursework. Your teacher will review it soon...',
         confirmButtonText: 'OK',
         confirmButtonColor: '#3085d6',
         timer: 3000 // Optional: Auto close after 3 seconds
       });
      }
      }
      if (error){
        setError("Error, We encountered an issue while trying to create the assesment . Please check your input and try again. If the problem persists, contact support for assistance.")
        console.log("error create",error)
      }
    }else {
      const  { data, error } = await editStudentSub({id:assignment.studentSubmission.id,content:answer})
      console.log('assignment.rrrr data,',data)

      if (data){
        setSubmission(data)
        if(submissionState==="submitted"){
          return  Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Thank you for submitting your coursework. Your teacher will review it soon...',
            confirmButtonText: 'OK',
            confirmButtonColor: '#3085d6',
            timer: 3000 // Optional: Auto close after 3 seconds
          });
        }
      }
      if (error){
        setError("Error, We encountered an issue while trying to update the assesment . Please check your input and try again. If the problem persists, contact support for assistance.")
        console.log("error update",error)
      }
    }


  }


  const toggle = (index) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
console.log('immmg',assignment)

  return (
    <>
      <div className={`accordion`}>
        {error && (

            // <div className="__errorMessage __errorField">
            //     <InfoCircleOutlined style={{color: "red"}} /> {error}
            // </div>
            <Alert message={error} type="error" icon={<InfoCircleOutlined /> } showIcon className="__errorField"/>
        )}
        <div className="accordion__title" onClick={() => toggle(1)}>
          <Tooltip title={assignment?assignment.coursework.description:""} placement={"top"}>
            <h1>{assignment?assignment.coursework.title:""}</h1>
          </Tooltip>
          <DoubleRightOutlined className={`arrow ${accordionStates[1] ? "toggled" : ""}`}/>
        </div>
        <div className={`accordion__content  ${ accordionStates[1] ? "toggled" : ""}`}>
          <img src={image ? `${process.env.REACT_APP_API_BASE_URL}/uploads/images/${image}` : AssignmentImage} alt={assignment?assignment.coursework.title:""} />
          <div>
            <h1>{assignment?assignment.coursework.title:""}</h1>
            <p>
                {assignment?assignment.coursework.description:""}
            </p>
          </div>
        </div>
      </div>

      <div className={`accordion`}>
        <div className="accordion__title-student" onClick={() => toggle(2)}>

          <h1>Student Couresework</h1>
          <DoubleRightOutlined className={`arrow ${accordionStates[2] ? "toggled" : ""}`}/>
        </div>
        <div className={`accordion__content-student  ${ accordionStates[2] ? "toggled" : ""}`}>
          <Slate
              editor={editor}
              initialValue={
                answer
              }
              onChange={value => {
                const isAstChange = editor.operations.some(
                    op => 'set_selection' !== op.type
                )
                if (isAstChange) {
                  // Serialize the value and save the string value to Local Storage.
                  setAnswer(value)

                }
              }}
          >
            <Editable  className={"slate"}   style={{
              minHeight: '400px',
              padding:"10px"

            }}/>
          </Slate>
          <Button className="accordion__upload-btn">
            <FileAddOutlined style={{fontSize:"20px"}}/>
            <input type="file" name="myfile" onChange={extractText}/>
          </Button>

        </div>


        {submissionState && submissionState === "viewed"?<span className="already-submitted">  <Image width={20} src={logoBtn} alt="Background"  preview={false}/> Coursework already checked by Teacher</span>:
            <Button type="primary" onClick={handleSubmit}
                    style={{marginLeft: 8, float: 'right', marginTop: 6, marginBottom: 10 }}  className={"button button-big btn-correction"}>
              <Image width={20} src={logoBtn} alt="Background"  preview={false}/>
              Submit
            </Button>
        }
      </div>
    </>
  );
};

export default StudentAssignmentAccordion;


const studentCouresWork=[`The Hoode
Mispellings and grammatical errors can effect your credibility. The same goes for misused commas, and other types of punctuation . Not only will Grammarly underline these issues in red, it will also showed you how to correctly write the sentence.

Underlines that are blue indicate that Grammarly has spotted a sentence that is unnecessarily wordy. You'll find suggestions that can possibly help you revise a wordy sentence in an effortless manner.

But wait...there's more?
Premium can give you very helpful feedback on your writing. Passive voice can be fixed by , and it can handle classical word-choice mistakes. It can also help with inconsistencies such as switching between e-mail and email or the U.S.A. and the USA.

It can even help when you wanna refine ur slang or formality level. That's especially useful when writing for a broad audience ranging from businessmen to friends and family, don't you think? It'll inspect your vocabulary carefully and suggest the best word to make sure you don't have to analyze your writing too much.

a refine ur slang or formality level. That's especially useful when writing for a broad audience ranging from businessmen to friends and family, don't you think? It'll inspect your vocabulary carefully and suggest the best word to make sure you don't have to analyze your writing too much.

It can even help when you wanna refine ur slang or formality level. That's especially useful when writing for a broad audience ranging from businessmen to friends and family, don't you think?

It'll inspect your vocabulary carefully and suggest the best word to make sure you don't have to analyze your writing too much.

writing for a broad audience ranging from businessmen to friends and family, don't riting too much.writing for a broad audience ranging from businessmen to friends and family, don't you think? It'll inspect your vocabulary carefully and suggest the best word to make sure you don't have to analyze your writing too much.`
]