import { initialState } from './app.slice';
import { createSelector } from '@reduxjs/toolkit';

const selectDomain = (state) => state.app ?? initialState;


export const selectCorrection = createSelector([selectDomain], (domainState) => {
    return domainState.correction;
});

export const selectIsCorrected = createSelector([selectDomain], (domainState) => {
    return domainState.isCorrected;
});

export const selectStateError = createSelector([selectDomain], (domainState) => {
    return domainState.stateError;
});

export const selectSlateText = createSelector([selectDomain], (domainState) => {
    return domainState.slateText;
});
export const selectTextBrut = createSelector([selectDomain], (domainState) => {
    return domainState.textBrut;
});


export const selectLoading = createSelector([selectDomain], (domainState) => {
    return domainState.loading;
});