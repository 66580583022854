import CriteriaScore from "../criteriaScore/CriteriaScore";
import deleteIcon from "../../assets/images/delete.svg"
import { PlusOutlined } from "@ant-design/icons"
import { Button, Input } from "antd";
import { useState } from "react";
import "./criteria.css"


const { TextArea } = Input;


const Criteria = ({ criteria, onDelete, onTitleChange, onScoreChange, addScore, deleteScore, deleteAllScores, error }) => {
    return (
        <div className="rubric__form-criteria">
            <div className="criteriaTitle">
                <div className="criteriaTitle-field">
                    <label htmlFor="criteriaTitle">Criteria Title</label>
                    <Input
                        id="criteriaTitle"
                        placeholder="Enter title"
                        value={criteria.title}
                        onChange={(e) => onTitleChange('title', e.target.value)}
                    />
                    {error && <div className="errorMessage">{error.title}</div>}
                    <TextArea
                        className="description"
                        placeholder={"Enter description"}
                        name={"description"}
                        value={criteria.description}
                        onChange={(e) => onTitleChange('description', e.target.value)}
                        />
                    {error && <div className="errorMessage">{error.description}</div>}
                </div>
                <Button className="delete" onClick={onDelete}>
                    <img src={deleteIcon} width={15} alt="delete icon" />
                </Button>
            </div>
            <div className="scoreDefinitions">
                <div className="scrolling-wrapper">
                    {criteria.levels.map((score, index) => (
                        <CriteriaScore
                            key={index}
                            score={score}
                            onScoreChange={(field, value) => onScoreChange(index, field, value)}
                            onDeleteScore={() => deleteScore(index)}
                            error={error && error.levels && error.levels[index]}
                        />
                    ))}
                </div>
                <Button className="delete" onClick={addScore}>
                    <PlusOutlined style={{ fontSize: "15px", color: "#fff" }} />
                </Button>
                <Button className="add" onClick={deleteAllScores}>
                    <img src={deleteIcon} width={15} alt="delete icon" />
                </Button>
            </div>
        </div>
    );
};

export default Criteria;
