import React, { useEffect, useState }  from 'react';
import {Row, Col, Typography, Collapse,Image} from 'antd';
import '../sidebar/Sidebar.css';
import './Statistics.css';
import english from "../../assets/images/english.svg";
import statisticsData from '../../data/Statstics.json';
import {useSelector} from "react-redux";
import {selectCorrection, selectIsCorrected,selectTextBrut} from "../../redux/app/app.selectors";
const { Text } = Typography;
const { Panel } = Collapse;

const Statistics = () => {
    const [data, setData] = useState(null);
    const correction = useSelector(selectCorrection);
    const isCorrected = useSelector(selectIsCorrected);
    const textBrut = useSelector(selectTextBrut)

    let readingTime = 0
    let hours = 0
    let minutes = 0
    let quality = 0
    let errorTypes=[]
    let totalErrors= 0
    let num_words = 0
    let lengthText = 0

    if(textBrut){
        lengthText = textBrut.length
    }
    if(correction){
        if(correction.error_composition && correction.error_composition.length>0){
            errorTypes = correction.error_composition[0]
            totalErrors = Object.values(errorTypes).reduce((a, b) => a + b, 0);
        }
            if(correction.num_words){
            num_words = correction.num_words
             const charactersPerMinute = 180
            readingTime = Math.ceil(num_words/charactersPerMinute)
             hours = Math.floor(readingTime / 60);
             minutes = readingTime % 60;

            }
        if(num_words && totalErrors){
            quality=((num_words-totalErrors)/num_words).toFixed(2)
            // rendre la qualité en pourcentage
            quality = quality*100+"%"
        }
    }
    // calculate reading time sachant que 180 mots par minute


    useEffect(() => {
        // Simuler le chargement des données
        setData(statisticsData);
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }
    const renderContent = (key, value) => {
        if (key === "Language") {
            return (
                <Text className={"text text-error-type"}>
                    <Image width={20} src={english} alt="Background"  preview={false}/> {value}
                </Text>
            );
        }
        return <Text className={"text text-error-type"}>{value}</Text>;
    };
    return (
    <Collapse defaultActiveKey={['1']} expandIconPosition='right' className={"collapse"}>
        <Panel key="1" header={<span className={"font-weight-500"}>Statistics</span>} className={"side-card-title"}>
            {isCorrected ?  <Row gutter={[16, 16]}>
                    <Col  className={"col-statistics"} span={24}>

                        <Text className={"text"}>Text Quality</Text>
                        <Text className={"text"}>{quality}</Text>

                    </Col>
                <Col className={"col-statistics"}  span={24}>
                    <Text className={"text"}>Language</Text>
                    <Text className={"text"} style={{display:'flex'}}><img src={english}/>English</Text>
                </Col>
                <Col className={"col-statistics"}  span={24} >
                    <Text className={"text"}>Words</Text>
                    <Text className={"text"}>{num_words}</Text>

                </Col>
                <Col className={"col-statistics"}  span={24} >
                    <Text className={"text"}>Characters</Text>
                    <Text className={"text"}>{lengthText}</Text>
                </Col>
                <Col className={"col-statistics"}   span={24} >
                    <Text className={"text"}>Reading Time</Text>
                    <Text className={"text"}>{hours}h {minutes}min</Text>
                </Col>
            </Row> : <Row gutter={[16, 16]}></Row>}
        </Panel>
    </Collapse>
    );
};

export default Statistics;