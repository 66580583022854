import { api } from './api';

export const StudentSubmissionAssisment = api.injectEndpoints({
    endpoints: (builder) => ({


        createStudentSubmissionAssisment: builder.mutation({
            query: ( studentsubmission) => {
                console.log("rubricData ==>", studentsubmission);
                return {
                    url: `/api/student-submission-assesments`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },

                    body: JSON.stringify(studentsubmission),
                }
            },
            invalidatesTags: [{ type: 'StudentSubmissions', id: 'LIST' }],
        }),
    }),
});

export const {useCreateStudentSubmissionAssismentMutation} = StudentSubmissionAssisment;