import StudentAssignmentAccordion from "../../Components/accordion/student-assignment-accordion/StudentAssignmentAccordion";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import {useEffect, useState} from "react";
import AppLayout from "../Layout/AppLayout";
import "./StudentSubmissionDetails.css";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import {useLazyGetStudentcourseWorkQuery} from "../../services/studentcourseworks.api";


const rubricScore = [
    {
        criteriaTitle : "Grammer usage",
        scores : ["score1","score2","score3"]
    },
    {
        criteriaTitle : "Paraghraph Structure",
        scores : ["score1","score2","score3"]
    },
    {
        criteriaTitle : "Vocabulary usage",
        scores : ["score1","score2","score3"]
    },
]

const StudentSubmissionDetails = () => {
    const [getStudentCourseWork,{data,error,isLoading}] =  useLazyGetStudentcourseWorkQuery()
    const { assignmentId } = useParams();
    console.log('assignmentId', assignmentId)


    useEffect(() => {
        getStudentCourseWork(assignmentId);
    }, [assignmentId]);

    if (isLoading) return <CustomSpin />;


    let title="This is your coursework"
    console.log("sdsdd",data)

    return (
      <AppLayout hideHeader={true}>
         <div className="teacher__container">
            <h1 className="teacher__container-title">
                    <span>CourseWork</span>
                    <InfoCircleOutlined style={{fontSize:"10px"}} className={"icon-tooltip"} />
            </h1>
            <div className="teacher__assignment" style={{display:"block"}}>
                <div className="right">
                    <StudentAssignmentAccordion assignment={data}/>
                </div>
            </div>
         </div>
      </AppLayout>




  )
}

export default StudentSubmissionDetails
