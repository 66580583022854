import React, { useEffect, useState } from 'react';
import {Row, Collapse} from 'antd';
import '../sidebar/Sidebar.css';
import './SideCorrection.css';
import BtnCorrection from './BtnCorrection';
import BlockCorrection from './BlockCorrection';
import { selectStateError } from '../../redux/app/app.selectors';
import { useSelector } from 'react-redux';
const { Panel } = Collapse;
const SideCorrection = () => {
   
    const error = useSelector(selectStateError)
    const [errorElements,setErrorElments] = useState([])
   
   


    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition='right' className={"collapse"}>
            <Panel header={<span className={"font-weight-500"}>Change</span>} key="1" className={"side-card-title"}>
               <div className={"side-card-content"}>
                   <Row gutter={[16, 16]}>
                
                      {
                        error.map(v => <BlockCorrection sentance={v}/>)
                      }
                     
                   </Row>
               </div>
            </Panel>
        </Collapse>

    );
};

export default SideCorrection;