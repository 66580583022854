import { Modal} from 'antd';
import ViewRubricTable from '../viewRubricTable/ViewRubricTable';
import {useEffect} from "react";

const ViewRubricModal = ({isViewModalOpen, setIsViewModalOpen, rubricItem, rubricItemData}) => {

    const handleCancel=()=>{
        setIsViewModalOpen(!isViewModalOpen)
    }

    console.log('rubricItem =>', rubricItem);
    console.log('rubricData =>', rubricItemData);

    return (
        <Modal title={rubricItemData?.title} open={isViewModalOpen} onCancel={handleCancel} footer={null} width={920} style={{ top: 38 }}>
            {rubricItemData?.criterias.map((item, index) => (
                <ViewRubricTable key={index} criteria={item} />
            ))}
        </Modal>
    )
}

export default ViewRubricModal