import { Formik, Form, Field, ErrorMessage } from "formik";
import remediaLogo from "../../../assets/images/remedia-logo.png";
import { Input, Button, Checkbox, Spin, Alert } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { object, string, ref, boolean } from "yup";
import { Link } from "react-router-dom";
import "./signUp.css";
import {useRegisterMutation} from "../../../services/register.api";
import {useState} from "react";

const SignUp = ({ data, handleFormSubmit, loading }) => {
  const [createUser] = useRegisterMutation();
  const [errorMessage, setErrorMessage] = useState(null);

  const validationSchema = object().shape({
    firstName: string().required("Required*"),
    lastName: string(),
    email: string().email("Invalid email address").required("Required*"),
    password: string()
      .min(5, "Password must be at least 5 characters")
      .required(""),
    confirmPassword: string()
      .oneOf([ref("password"), null], "Passwords must match")
      .required(""),
    terms: boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("You must accept the terms and conditions"),
  });

  const handleSubmit =  async (values, { setSubmitting }) => {


    const { data, error: userError } = await createUser(values);

    if(data){
      handleFormSubmit(values);
    }
    if (userError){
      setErrorMessage('An error occurred while creating your account. Please try again.');
      setSubmitting(false);

    }
  };

  return (
    <main className={"register__page"}>
      <img src={remediaLogo} alt="remedia logo" className="__remediaLogo" />
      <div className="container">
        <div className="register __box">
          <h1 className="__title"> Sign Up </h1>
          <span className="register__subTitle __mixSubTitle">
            Already have an account? <Link to="/sign-in"> Sign in here </Link>
          </span>

          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, isValid, errors }) => (
              <Form className="register__form">
                {errorMessage && (
                    <Alert
                        message={errorMessage}
                        type="error"
                        icon={<InfoCircleOutlined />}
                        showIcon
                        className="__errorField"
                    />
                )}
                {!isValid && Object.keys(errors).length > 0 && (
                  <Alert
                    message="Please fill out all fields to proceed"
                    type="error"
                    icon={<InfoCircleOutlined />}
                    showIcon
                    className="__errorField"
                  />
                )}
                <div className="register__form-fullName">
                  <div className="register__form-firstNameField">
                    <Field name="firstName">
                      {({ field }) => (
                        <Input {...field} placeholder="First Name" size="large" type="text" className="register__form-firstName"/>
                      )}
                    </Field>
                    {/* <ErrorMessage name="firstName" component="div" className="__errorMessage"/> */}
                  </div>

                  <div className="register__form-lastName">
                    <Field name="lastName">
                      {({ field }) => (
                        <Input {...field} placeholder="Last Name" size="large" type="text" className="register__form-lastName"/>
                      )}
                    </Field>
                    {/* <ErrorMessage name="lastName" component="div" className="__errorMessage"/> */}
                  </div>
                </div>

                <div className="register__form-emailField">
                  <Field name="email">
                    {({ field }) => (
                      <Input {...field} placeholder="Alexanderdoe@email.com" size="large" type="email" className="register__form-email"/>
                    )}
                  </Field>
                  {/* <ErrorMessage name="email" component="div" className="__errorMessage"/> */}
                </div>

                <div className="register__form-passwordField">
                  <Field name="password">
                    {({ field }) => (
                      <Input.Password {...field} placeholder="Password" type="password" size="large" className="register__form-password"/>
                    )}
                  </Field>
                  <ErrorMessage name="password" component="div" className="__errorMessage"/>
                </div>

                <div className="register__form-confirmPasswordField">
                  <Field name="confirmPassword">
                    {({ field }) => (
                      <Input.Password {...field} placeholder="Confirm password" type="password" size="large" className="register__form-confirmPassword"/>
                    )}
                  </Field>
                  <ErrorMessage name="confirmPassword" component="div" className="__errorMessage"/>
                </div>

                <div className="register__form-checkboxField">
                  <Field name="terms">
                    {({ field }) => (
                      <Checkbox  {...field} className="register__form-checkbox">
                        By clicking create an account, I agree that I have read
                        and accepted the <a href="?#">Terms of Use</a> and{" "}
                        <a href="?#">Privacy Policy</a>
                      </Checkbox>
                    )}
                  </Field>
                  {/* <ErrorMessage name="terms" component="div" className="__errorMessage"/> */}
                </div>

                <Button type="primary" htmlType="submit" block className="register__form-btn __authBtn" disabled={isSubmitting}>
                  {loading ? <Spin /> : "Continue"}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
};

export default SignUp;
