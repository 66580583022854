import { useState } from "react";
import ChoseRole from "../chose-role/ChoseRole";
import SignUp from "../sign-up/SignUp";
import Verification from "../verification/Verification";
const SignUpFlow = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [loading , setLoading] = useState(false)
    const [data, setData] = useState({
        type: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        terms : false
    });

    const handlNextStep = (newData) => {
        setData(prev => ({...prev , ...newData}))
        setCurrentStep(prev => prev + 1)
    }

    const handleFormSubmit = (formData) => {
        setLoading(true)
        setTimeout(() => {
            console.log(formData);
            setData(prev => ({...prev , ...formData}))
            setCurrentStep(prev => prev + 1);
            setLoading(false);
        }, 1000);
    };

    const Steps = [
        <ChoseRole nextStep={handlNextStep} data={data}/>,
        <SignUp data={data} handleFormSubmit={handleFormSubmit} loading={loading}/>, 
        <Verification user={data} />
    ]

    return (
        <>
            {Steps[currentStep]}
        </>
    )
}

export default SignUpFlow