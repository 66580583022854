import { createSlice } from '@reduxjs/toolkit';
import { authApi } from '../../services/auth.api';
import {logDOM} from "@testing-library/react";

export const initialState = {
  access_token:null,
  refresh_token:null,
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.access_token = action.payload.token;
      state.refresh_token= action.payload.refresh_token
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.access_token = null;
      state.refresh_token = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
        state.access_token = action.payload.token;
        state.refresh_token = action.payload.refresh_token;
        state.user = action.payload.user;
      })
      .addMatcher(authApi.endpoints.getMe.matchFulfilled, (state, action) => {
        state.user = action.payload;
      })
  },
});

export const {
  setCredentials,
  logout,
} = authSlice.actions;
export const { name: sliceKey } = authSlice;

export default authSlice.reducer;
