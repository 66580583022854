import "./viewRubricTable.css";


const ViewRubricTable = ({ criteria }) => {
  return (
      <>
          <h2 className="viewRubricTable__title">
              {criteria.title}
          </h2>
          <p className={"viewRubricTable__description"}>{criteria.description}</p>
          <table className="viewRubricTable">
              <tbody className="viewRubricTable__body">
              <tr className="viewRubricTable__body-top">
                  {criteria.levels.map((item, index) => (
                      <td key={index}>
                          {item.title} / {item.point}pt
                      </td>
                  ))}
              </tr>

              <tr className="viewRubricTable__body-bottom">
                  {criteria.levels.map((item, index) => (
                      <td key={index}>
                          {item.description}
                      </td>
                  ))}
              </tr>
              </tbody>
          </table>
      </>
  );
};

export default ViewRubricTable;