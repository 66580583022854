import "./editRubricTable.css";


const EditRubricTable = ({ criteria, handleLevelChange ,handleTitleChange}) => {
  return (
      <>
          <div className="editRubricTable__title">
              <input
                  type="text"
                  value={criteria.title}
                  onChange={(value) => handleTitleChange('title', value.target.value)}/>

          </div>
          <div className="editRubricTable__description">
              <textarea
                value={criteria.description}
                onChange={(value) => handleTitleChange('description', value.target.value)}
              />
          </div>
          <table className="editRubricTable">

              <tbody className="editRubricTable__body">
              <tr className="editRubricTable__body-top">
                  {criteria.levels.map((item, index) => (
                      <td key={index}>
                          <div className={"level__title"}>
                              <input
                                  value={item.title}
                                  className={"item__title"}
                                  onChange={(value) => handleLevelChange(index, 'title', value.target.value)}
                              /> / <input
                              value={item.point}
                              className={"item__point"}
                              onChange={(value) => handleLevelChange(index, 'point', parseInt(value.target.value))}
                          />pt
                          </div>
                      </td>
                  ))}
              </tr>

              <tr className="editRubricTable__body-bottom">
                  {criteria.levels.map((item, index) => (
                      <td key={index}>
                  <textarea
                      value={item.description}
                      onChange={(value) => handleLevelChange(index, 'description', value.target.value)}
                  />
                      </td>
                  ))}
              </tr>
              </tbody>
          </table>
      </>
  );
};

export default EditRubricTable;