import { api } from './api';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({

         createAssignment: builder.mutation({
             query: (formData) => ({
                 url: '/api/courseworks',
                 method: 'POST',
                 body: JSON.stringify(formData),
                 headers: {'Content-Type': 'application/json'},
             }),
                invalidatesTags: [{type: 'Assignments', id: 'ALL'}],
         }),

        updateAssignment: builder.mutation({

            query: (formData) => {
                console.log('formData', formData);
                return ({
                    url: `/api/courseworks/${formData.id}`,
                    method: 'PATCH',
                    body: JSON.stringify(formData),
                    headers: {'Content-Type': 'application/merge-patch+json'},
                });

            },
            invalidatesTags: [{type: 'Assignments', id: 'ALL'}],
        }),

        getAssignments: builder.query({
            query: () => ({
                url: '/api/courseworks',
                method: 'GET',
            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        {type: 'Assignments', id: 'ALL'},
                    ],
        }),
        getAssignmentByFilter: builder.query({
            query: (filter) => `/api/courseworks?title=${filter}&subject=${filter}&description=${filter}&grade=${filter}`,
        }),


    }),
});

export const { useCreateAssignmentMutation, useLazyGetAssignmentsQuery , useGetAssignmentByFilterQuery, useUpdateAssignmentMutation } = authApi;