import { api } from './api';

export const StudentcourseWorks = api.injectEndpoints({
    endpoints: (builder) => ({
        getStudentcourseWorks: builder.query({
            query: () => ({
                url: '/api/student-course-works',
                method: 'GET',

            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'StudentCourseWorks', id: 'LIST' },
                    ],
        }),
        getStudentcourseWork: builder.query({
            query: (id) => ({
                url: `/api/student-course-works/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'StudentCourseWorks', id: id }],
        }),

        createStudentcourseWork: builder.mutation({
            query: (formData) => ({
                url: '/api/student-course-works',
                method: 'POST',
                body: JSON.stringify(formData),
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags: [{type: 'StudentCourseWorks', id: 'ALL'}],
        }),

         getCheckStudentcourseWork: builder.query({
            query: ({student, course}) => ({
                url: `/api/check-essay/${student}/${course}/check`,
                method: 'GET',
            }),
    }),


        uploadHandwriting: builder.mutation({
            query: (data) => ({
                url: '/api/upload-handwriting',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: [{ type: 'StudentCourseWorks', id: 'LIST' }],
        }),
    }),
});

export const { useGetStudentcourseWorksQuery,useLazyGetStudentcourseWorkQuery,useLazyGetCheckStudentcourseWorkQuery,useCreateStudentcourseWorkMutation,useUploadHandwritingMutation } = StudentcourseWorks;