import { api } from './api';

export const CourseWorks = api.injectEndpoints({
    endpoints: (builder) => ({
        getCourseWorks: builder.query({
            query: () => ({
                url: '/courseworks',
                method: 'GET',

            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'CourseWorks', id: 'LIST' },
                    ],
        }),
        getCourseWork: builder.query({
            query: (assignmentId) => ({
                url: `/api/courseworks/${assignmentId}`,
                method: 'GET',
            }),
            providesTags: (result, error, { assignmentId }) => [{ type: 'CourseWorks', id: assignmentId }],
        }),
        createNewCoursework: builder.mutation({
            query: ({ formData }) => ({
                url: `/api/course-works/new`,
                method: 'POST',
                body: formData,
                attachments: formData,
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'CourseWorks', id: 'LIST' },
                    ],
        }),

        deleteCourseWork: builder.mutation({
            query: ({ assignmentId }) => ({
                url: `/api/courseworks/${assignmentId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'CourseWorks', id: 'LIST' },
                    ],
        }),

    }),
});

export const { useLazyGetCourseWorksQuery,useLazyGetCourseWorkQuery, useCreateNewCourseworkMutation, useDeleteCourseWorkMutation} = CourseWorks;