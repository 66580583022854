import React from 'react';
import {Row, Col, Collapse, Button, Input, message} from 'antd';
import '../sidebar/Sidebar.css';
import './Comment.css';
import {SaveOutlined} from "@ant-design/icons";
const { Panel } = Collapse;
const { TextArea } = Input;
const Comment = () => {
    const handleButtonClick = () => {
        message.success(`Comments saved successfully²`);
    };
    return (
        <Collapse defaultActiveKey={['1']} expandIconPosition='right' className={"collapse"}>
            <Panel header={<span className={"font-weight-500"}>Comment</span>} key="1" className={"side-card-title"}>
                <Row gutter={[16, 16]}>
                    <Col span={24} style={{textAlign: 'left', display: 'flex', padding: 0}}>
                        <TextArea rows={4} placeholder="Comment Here ..." maxLength={6}/>
                    </Col>
                    <Col span={24} style={{ padding: 0}}>
                        <Button type="primary" icon={<SaveOutlined />} onClick={handleButtonClick}  style={{ marginLeft: 8,float: 'right' }} className={"btn-comment"}>Save</Button>
                    </Col>
                </Row>
            </Panel>
        </Collapse>

    );
};

export default Comment;