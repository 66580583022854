import {PlusCircleFilled} from "@ant-design/icons"
import { Button, Modal, Select , Input} from 'antd';
import {Formik} from "formik";
import {useEffect, useState , useRef} from "react";
import { useLazyGetRubricsQuery,useLazyGetRubricByIdQuery } from "../../services/rubrics.api";
import "./newAssignment.css";
import {useCreateAssignmentMutation} from "../../services/assignments.api";
import {AssignmentSchema} from "../../Pages/Assignments/form.schema";
import {useCreateNewCourseworkMutation} from "../../services/coursworks.api";
import {useGetMeQuery} from "../../services/auth.api";
import Swal from 'sweetalert2';

const { TextArea } = Input;

const NewAssignment = ({isModalOpen,onClose,...props}) => {
    const [triggerRubric, {data: rubricData, isLoading:isLoadingRubrics }] = useLazyGetRubricsQuery();
    const [getRubricById, { data: rubricById, isLoading: isLoadingRubricById }] = useLazyGetRubricByIdQuery();
    const [selectedImageURL, setSelectedImageURL] = useState();
    const [selectedImage, setSelectedImage] = useState();
    const [showSelect, setShowSelect] = useState(false);
    const [createAssignment, {  isLoading }] = useCreateAssignmentMutation(); // Use the mutation hook
    const [triggerCreateCoursework, {data, isLoading:isLoadingCreate}] = useCreateNewCourseworkMutation()
    const [selectedRubricTitle, setSelectedRubricTitle] = useState('');
    const [selectedRubricId, setSelectedRubricId] = useState('');
    const { data: authenticatedUser, error } = useGetMeQuery();
    let teacher=null

    useEffect(() => {
        triggerRubric()
    },[triggerRubric]);
    const openRubrics = () => {
        setShowSelect(!showSelect);
    }

    const fileInputRef = useRef(null);

    const handleRubricChange = async (value,setFieldValue) => {
        const { data } = await getRubricById(value);
        console.log(data,rubricById,'data rubric++++++++')
        if (data) {
            setFieldValue('rubric', data['@id']);
            setSelectedRubricTitle(data.title); // Set the selected rubric title
            setSelectedRubricId(data.id); // Set the selected rubric title
        }
    };

    function handleImageImport(e) {
        console.log('eeeeee',e)
        if (e.target.files.length) {
            console.log(e.target.files[0]);
            setSelectedImage(e.target.files[0]);
        }
    }

    const handleSubmit = async (values) => {

        if(authenticatedUser){
            teacher= authenticatedUser.id
        }

        const formData = new FormData();
        formData.append('image', selectedImage);


        const assignmentData = {
            ...values,
            teacher:teacher,
            rubric:selectedRubricId,
        };

        formData.append('data',JSON.stringify(assignmentData) );


        const { data, error } = await triggerCreateCoursework({
            formData: formData,
        });

        setSelectedImage(null);
        setSelectedImageURL(null);

        if (null === data && undefined === error) {
            // close the modal
            onClose();
            return Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'CourseWork created successfully.',
                confirmButtonText: 'OK',
                confirmButtonColor: '#3085d6',
                timer: 3000 // Optional: Auto close after 3 seconds
            });
        }
        if (error) {
            console.log('image not uploaded');
        }
    };
    return (
        <>
            <Modal title="New CourseWork" open={isModalOpen} onCancel={onClose} footer={null} className='newAssignment-modal'>
                <Formik
                    initialValues={{ title: '', type: 'Assignment', subject: 'English', grade: '4th grade', description: '', rubric: '',state:'active'}}
                    validationSchema={AssignmentSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    onSubmit={async (values, { setSubmitting }) => {
                        console.log(values)
                        handleSubmit(values)

                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          setFieldValue,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <form className={"newAssignment-form"} onSubmit={handleSubmit}>
                            <div className="assignmentFields">
                                <span className='assignmentFields-title'>CourseWork Title*</span>
                                <Input
                                    name="title"
                                    value={values.title}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.title && touched.title && <div className="error">{errors.title}</div>}

                            </div>
                            <div className="assignmentFields">
                                <span className='assignmentFields-title'>Format*</span>
                                <p className='assignmentFields-desc'>Add information about the format</p>
                                <Select
                                    defaultValue="Assignment"
                                    name="type"
                                    style={{width: 120,}}
                                    onChange={value => setFieldValue('type', value)}
                                    options={[
                                        {
                                            value: 'Assignment',
                                            label: 'Assignment',
                                        },
                                        {
                                            value: 'MCQ',
                                            label: 'MCQ',
                                        },
                                        {
                                            value: 'Q/R',
                                            label: 'Q/R',
                                        }
                                    ]}
                                />
                                {errors.type && touched.type && <div className="error">{errors.type}</div>}

                            </div>
                            <div className="assignmentFields">
                                <span className='assignmentFields-title'>Subject*</span>
                                <p className='assignmentFields-desc'>Add information about the Subject</p>
                                <Select
                                    defaultValue="English"
                                    style={{width: 120,}}
                                    name="subject"
                                    onChange={value => setFieldValue('subject', value)}
                                    options={[
                                        {
                                            value: 'English',
                                            label: 'English'
                                        }
                                    ]}
                                />
                                {errors.subject && touched.subject && <div className="error">{errors.subject}</div>}
                            </div>
                            <div className="assignmentFields">
                                <span className='assignmentFields-title'>Grade*</span>
                                <Select
                                    defaultValue="4th grade"
                                    style={{width: 120,}}
                                    name="grade"
                                    onChange={value => setFieldValue('grade', value)}
                                    options={[
                                        {
                                            value: '4th grade	',
                                            label: '4th grade	'
                                        },
                                        {
                                            value: '5th grade',
                                            label: '5th grade',
                                        },
                                        {
                                            value: '6th grade',
                                            label: '6th grade',
                                        }
                                    ]}
                                />
                                {errors.grade && touched.grade && <div className="error">{errors.grade}</div>}
                            </div>

                            <div className="assignmentFields">
                                <span className='assignmentFields-title'>State*</span>
                                <Select
                                    defaultValue="active"
                                    style={{width: 120,}}
                                    name="state"
                                    onChange={value => setFieldValue('state', value)}
                                    options={[
                                        {
                                            value: 'active',
                                            label: 'active'
                                        },
                                        {
                                            value: 'draft',
                                            label: 'draft'
                                        }
                                    ]}
                                />
                                {errors.state && touched.state && <div className="error">{errors.state}</div>}
                            </div>
                            <div className="assignmentFields">
                                <span className='assignmentFields-title'>Instructions</span>
                                <p className='assignmentFields-desc'>Provide instructions or reference texts for
                                    students
                                    below.<br/>This text will be visible to students as they compose their responses.
                                </p>
                                <TextArea
                                    rows={5}
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                />
                                {errors.description && touched.description &&
                                    <div className="error">{errors.description}</div>}

                                <div className="assignmentFields last-child">
                                    <label style={{textAlign: "right"}}>
                                        <input hidden type="file" onChange={handleImageImport}/>
                                        <div className={'flex-center gap-xs'} style={{fontWeight: "600", fontSize: "10px", lineHeight: "13px", cursor: "pointer", color: "#000095", marginTop: "6px"}}>
                                            Add Image
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="assignmentFields">
                                <span className='assignmentFields-title'>Rubric</span>
                                <p className='assignmentFields-desc'>The rubric help you giving feedback and suggesting
                                    scores.</p>
                                <Button htmlType="button" className='assignmentFields-rubricBtn' onClick={openRubrics}>
                                <PlusCircleFilled/>
                                    Add Existence Rubric
                                </Button>
                                {showSelect && rubricData && (
                                    <Select
                                        className={"select-rubric"}
                                        style={{marginTop: 12}}
                                        placeholder="Select a rubric"
                                        name="rubric"
                                        // value={values.rubric}
                                        value={selectedRubricTitle || undefined}
                                        // onChange={value => setFieldValue('rubric', value)}
                                        onChange={value => handleRubricChange(value, setFieldValue)}
                                    >
                                        {rubricData['hydra:member'].map((rubric) => (
                                            <Select.Option key={rubric.id} value={rubric.id}>
                                                {rubric.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                {errors.rubric && touched.rubric && <div className="error">{errors.rubric}</div>}

                            </div>

                            <div style={{textAlign: "center"}}>
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="createAssignment-btn"
                                >
                                    Create
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}

export default NewAssignment