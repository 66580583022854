import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Typography, message} from 'antd';
import '../sidebar/Sidebar.css';
import './SideCorrection.css';
import {SaveOutlined,CheckOutlined,CopyOutlined} from "@ant-design/icons";

const { Text } = Typography;
const BlockCorrection = ({sentence}) => {
    const [appeared, setAppeared] = useState("");
    const [isOpen,setIsOpen] = useState(false);
    const [color, setColor] = useState("");
    const [status,setStatus] = useState("error");
    const [corrected,setCorrected] = useState(false);
    const handleButtonClick = () => {
        message.success("Text Copied");
    };
    const handleButtonSaveClick = () => {
        message.success("Correction saved");
    };

    return (
        <p style={{width: "100%",margin:0,paddingLeft: '3%'}}>
            <Col span={24} style={{textAlign: 'left', display: 'flex', padding: 0}} className={"col-line-correction"}>
                <span className={`dots dots-${color}`}></span>
                <Text className={"text"}>Change the verb</Text>
            </Col>
            <Col span={24} style={{textAlign: 'left', display: 'flex', padding: 0}}>
                <Text className={"text text-error-type"}>....will also showed show you how to ...</Text>
            </Col>
        
            <Col flex="auto">
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button onClick={handleButtonClick} type="default"  icon={<CopyOutlined/>} style={{ marginRight: 8 }} className={"button button-secondary button-small"}>
                                Copy
                            </Button>
                            <Button onClick={handleButtonSaveClick} type="default" icon={<SaveOutlined />}  style={{ marginRight: 8 }} className={"button button-secondary button-small"}>
                                Save
                            </Button>
                            <Button type="primary" icon={<CheckOutlined />}  style={{ marginRight: 8 }} className={"button button-small"}>
                                Correct
                            </Button>
                        </div>
                    </Col>
        </p>
        

    );
};

export default BlockCorrection;