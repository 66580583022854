import {PlusCircleFilled, EditOutlined} from "@ant-design/icons"
import {Button, Modal, Select, Input, message} from 'antd';
import {Formik, useFormikContext} from "formik";
import {useEffect, useState , useRef} from "react";
import { useLazyGetRubricsQuery,useLazyGetRubricByIdQuery, useLazyGetRubricItemQuery} from "../../services/rubrics.api";
import "./EditAssignment.css";
import {useUpdateAssignmentMutation,useCreateAssignmentMutation} from "../../services/assignments.api";
import {AssignmentSchema} from "../../Pages/Assignments/form.schema";
import {wait} from "@testing-library/user-event/dist/utils";

const { TextArea } = Input;

const EditAssignment = ({isModalOpen,onClose, coursWorkData,...props}) => {
    const [triggerRubric, {data: rubricData, isLoading:isLoadingRubrics }] = useLazyGetRubricsQuery();
    const [getRubricById, { data: rubricById, isLoading: isLoadingRubricById }] = useLazyGetRubricByIdQuery();
    const [getRubricItem, { data: rubricItem, isLoading: isLoadingRubricItem }] = useLazyGetRubricItemQuery();

    const [showSelect, setShowSelect] = useState(false);
    const [createAssignment, {  isLoading }] = useCreateAssignmentMutation(); // Use the mutation hook
    const [updateAssignment, {  isLoadingAssismentUpdating }] = useUpdateAssignmentMutation(); // Use the mutation hook

    const [selectedRubricTitle, setSelectedRubricTitle] = useState('');

  useEffect(() => {
    triggerRubric()
  },[triggerRubric]);
  const openRubrics = () => {
    setShowSelect(!showSelect);
  }
    console.log('rebric data   ++',rubricData)

    const fileInputRef = useRef(null);
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        // Check if rubricData and its structure exist
        console.log('gotten rubricData', coursWorkData && (coursWorkData.rubric ? coursWorkData.rubric : 'no rubric'));
        if (rubricData && coursWorkData && coursWorkData.rubric) {
            const selectedRubric = rubricData['hydra:member'].find(rubric => rubric['@id'] === coursWorkData.rubric);
            if (selectedRubric) {
                setSelectedRubricTitle(selectedRubric.title);
            }
            console.log('selectedRubric lmlmmlml ++', selectedRubric);
        }

        console.log('rebric data aaaaaaaaaaaaaaasssa  ++',rubricData)
    }, [rubricData, coursWorkData]);

    const handleRubricChange =  (value,setFieldValue) => {
        const selectedRubric = rubricData['hydra:member'].find(rubric => rubric['@id'] === value);
        console.log('selectedRubric', selectedRubric);
        if (selectedRubric) {
            // Set both the rubric value and the title in Formik's state
            setFieldValue('rubric', selectedRubric['@id']);
            setSelectedRubricTitle(selectedRubric.title);
        }
    };

    // Custom hook to reset form when coursWorkData changes
    const ResetFormOnCoursWorkDataChange = ({ data }) => {
        const { resetForm } = useFormikContext();

        useEffect(() => {
            resetForm({
                values: {
                    title: data.title || '',
                    type: data.type || 'Assignment',
                    subject: data.subject || 'English',
                    grade: data.grade || '',
                    description: data.description || '',
                    rubric: data.rubric || '',
                    state: data.state || 'active',
                }
            });
        }, [data, resetForm]);

        return null;
    };

    // Ensure the form renders only when coursWorkData is available
    if (!coursWorkData) {
        return null;
    }

  return (
    <>
      <Modal title="Edit CourseWork" open={isModalOpen} onCancel={onClose} footer={null} className='newAssignment-modal'>
        <Formik
            initialValues={{
                title: coursWorkData?.title || '',
                type: coursWorkData?.type || 'Assignment',
                subject: coursWorkData?.subject || 'English',
                grade: coursWorkData?.grade || '',
                description: coursWorkData?.description || '',
                rubric: coursWorkData?.rubric || '',
                state: coursWorkData?.state || 'active',
            }}
            validationSchema={AssignmentSchema}
            validateOnChange={false}
            validateOnBlur={false}
            // onSubmit={async (values, { setSubmitting }) => {
            //     const { data, error } = await createAssignment(values);

            onSubmit={async (values, { setSubmitting }) => {
                const currentDate = new Date().toISOString();
                const assignmentData = {
                    ...values,
                    updated_at:currentDate,
                    id: coursWorkData.id,
                };

                const { data, error } = await updateAssignment(assignmentData);
                if (error) {
                    console.log("err ++--++--++")
                    message.error('Error updating assignment');
                }

                if (data) {
                      onClose();
                      console.log("data --++--++", data);
                      message.success('Assignment updated successfully');
                }

                setSubmitting(false);
            }}
        >
          {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
                setFieldValue,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form className={"newAssignment-form"} onSubmit={handleSubmit}>
                  {/* Resets the form when coursWorkData changes */}
                  <ResetFormOnCoursWorkDataChange data={coursWorkData} />
                  <div className="assignmentFields">
                      <span className='assignmentFields-title'>CourseWork Title*</span>
                      <Input
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                      />
                      {errors.title && touched.title && <div className="error">{errors.title}</div>}

                  </div>
                  <div className="assignmentFields">
                      <span className='assignmentFields-title'>Format*</span>
                      <p className='assignmentFields-desc'>Add information about the format</p>
                      <Select
                          defaultValue="Assignment"
                          name="type"
                          style={{width: 120,}}
                          value={values.type}
                          onChange={value => setFieldValue('type', value)}
                          options={[
                              {
                                  value: 'Assignment',
                                  label: 'Assignment',
                              },
                              {
                                  value: 'MCQ',
                                  label: 'MCQ',
                              },
                              {
                                  value: 'Q/R',
                                  label: 'Q/R',
                              }
                          ]}
                      />
                      {errors.type && touched.type && <div className="error">{errors.type}</div>}

                  </div>
                  <div className="assignmentFields">
                      <span className='assignmentFields-title'>Subject*</span>
                      <p className='assignmentFields-desc'>Add information about the Subject</p>
                      <Select
                          defaultValue="English"
                          style={{width: 120,}}
                          name="subject"
                          value={values.subject}
                          onChange={value => setFieldValue('subject', value)}
                          options={[
                              {
                                  value: 'English',
                                  label: 'English'
                              }
                          ]}
                      />
                      {errors.subject && touched.subject && <div className="error">{errors.subject}</div>}
                  </div>
                  <div className="assignmentFields">
                      <span className='assignmentFields-title'>Grade*</span>
                      <Select
                          defaultValue="4th grade"
                          style={{width: 120,}}
                          name="grade"
                          value={values.grade}
                          onChange={value => setFieldValue('grade', value)}
                          options={[
                              {
                                  value: '4th grade	',
                                  label: '4th grade	'
                              },
                              {
                                    value: '5th grade',
                                    label: '5th grade',
                              },
                              {
                                  value: '6th grade',
                                  label: '6th grade',
                              }
                          ]}
                      />
                      {errors.grade && touched.grade && <div className="error">{errors.grade}</div>}
                  </div>

                  <div className="assignmentFields">
                      <span className='assignmentFields-title'>State*</span>
                      <Select
                          defaultValue="active"
                          style={{width: 120,}}
                          name="state"
                          value={values.state}
                          onChange={value => setFieldValue('state', value)}
                          options={[
                              {
                                  value: 'active',
                                  label: 'active'
                              },
                              {
                                  value: 'draft',
                                  label: 'draft'
                              }
                          ]}
                      />
                      {errors.state && touched.state && <div className="error">{errors.state}</div>}
                  </div>
                  <div className="assignmentFields">
                      <span className='assignmentFields-title'>Description</span>
                      <p className='assignmentFields-desc'>Provide instructions or reference texts for students
                          below.<br/>This text will be visible to students as they compose their responses.</p>
                      <TextArea
                          rows={5}
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                      />
                      {errors.description && touched.description && <div className="error">{errors.description}</div>}
                      <div className="assignmentFields">
                          <div style={{textAlign: "right"}}>
                              <button onClick={handleButtonClick} className='assignmentFields-addBtn'>Add Image</button>
                              <br/>
                              <input
                                  type="file"
                                  ref={fileInputRef}
                                  aria-label="Add Image"
                                  style={{display: 'none'}}
                              />
                          </div>
                      </div>
                  </div>
                  <div className="assignmentFields">
                      <span className='assignmentFields-title'>Rubric</span>
                      <p className='assignmentFields-desc'>The rubric help you giving feedback and suggesting
                          scores.</p>
                      {/*<Button className='assignmentFields-rubricBtn' onClick={openRubrics}>*/}
                      {/*    <EditOutlined/>*/}
                      {/*    Edite the Rubric*/}
                      {/*</Button>*/}
                      {rubricData && (
                          <Select
                              className={"select-rubric"}
                              style={{marginTop: 12}}
                              placeholder="Select a rubric"
                              name="rubric"
                              //value={values.rubric}
                              value={selectedRubricTitle || undefined}
                              // onChange={value => setFieldValue('rubric', value)}
                              onChange={value => handleRubricChange(value, setFieldValue)}
                          >
                              {rubricData['hydra:member'].map((rubric) => (
                                  <Select.Option key={rubric.id} value={rubric['@id']}>
                                      {rubric.title}
                                  </Select.Option>
                              ))}
                          </Select>
                      )}
                      {errors.rubric && touched.rubric && <div className="error">{errors.rubric}</div>}

                  </div>

                  <div style={{textAlign: "center"}}>
                      <button
                          type="submit"
                          disabled={isSubmitting}
                          className="createAssignment-btn"
                      >
                          Save
                      </button>
                    </div>
              </form>
              )}
        </Formik>
      </Modal>
    </>
  )
}

export default EditAssignment
