import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import MainRoutes from './routes';

const router = createBrowserRouter([{ path: '*', element: <MainRoutes /> }]);

const Root = () => {

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#2f80ed',
                    fontFamily: 'var(--font-family-sans-serif)',
                },
            }}
        ><RouterProvider router={router} />
        </ConfigProvider>
    );
};

export default Root;
