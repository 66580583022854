import { initialState } from './auth.slice';
import { createSelector } from '@reduxjs/toolkit';

const selectDomain = (state) => state.auth ?? initialState;

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  (domainState) => {
    return domainState.access_token !== null && domainState.user !== null;
  }
);

export const selectAuthenticatedUser = createSelector(
    [selectDomain],
    (domainState) => {
        return domainState.user;
    }
);

