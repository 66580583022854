
import React from "react";
import { Spin } from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import "./style.css";

const CustomSpin = () => {
    return (
        <div className={"data__loading"}>
            <Spin
                className={"data__loading__spin"}
                size={"large"}
                indicator={
                    <LoadingOutlined
                        style={{
                            fontSize: 48,
                            color: "#000095",
                        }}
                        spin
                    />
                }
            />
        </div>
    )
}

export default CustomSpin