import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {selectIsAuthenticated} from "../../redux/auth/auth.selectors";
import {useGetMeQuery} from "../../services/auth.api";
import {Navigate} from 'react-router-dom';
import {Spin} from "antd";

const ProtectedRoute = (props) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { data: authenticatedUser, error, isLoading } = useGetMeQuery();

  if (!isAuthenticated) {
    return <Navigate to={'/sign-in'} />;
  }
  if (isAuthenticated){

  }

  if (isLoading ) {
    return (
        <div className={"loading"}>
          <Spin
              className={"loading__spin"}
              size={"large"}  />
        </div>
    );
  }

  if (error) {
    // commented due to a bug when accessing the home directly with an expired token
    // Bug: Warning: Cannot update a component (`ProtectedRoute`) while rendering a different component (`ProtectedRoute`).
    // To locate the bad setState() call inside `ProtectedRoute`,
    // follow the stack trace as described in https://reactjs.org/link/setstate-in-render
    // TODO: fix this bug

    // dispatch(
    //   setNotification({
    //     title: 'Erreur',
    //     message: error.data?.message,
    //     type: 'danger',
    //   })
    // );

    return <Navigate to={'/sign-in'} />;
  }

  return <>{props.children}</>;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  role: PropTypes.string,
};

export default ProtectedRoute;
