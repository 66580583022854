import React, { useEffect, useState } from 'react';
import {Row, Col, Typography, Collapse, Button,Modal,Card,  Image} from 'antd';
import '../sidebar/Sidebar.css';
import './IssuesBlock.css';
import open from "../../assets/images/open.svg";
import ussuesDetails from '../../data/ussuesDetails.json';
import {CheckOutlined,BarChartOutlined} from "@ant-design/icons";
import Statistics from "../Statistics/Statistics";
import StatisticsModal from "../StatisticsModal/StatisticsModal";
import {useSelector} from "react-redux";
import {selectCorrection, selectIsCorrected} from "../../redux/app/app.selectors";
const { Text } = Typography;
const { Panel } = Collapse;

const IssuesBlock = () => {
    const [data, setData] = useState(null);
    const [visible, setVisible] = useState(false);

    const correction = useSelector(selectCorrection);
    const isCorrected = useSelector(selectIsCorrected);


    let errorTypes=[]
    let errorTypesArray = []

    if(isCorrected && correction && correction.error_composition && correction.error_composition.length>0) {
        errorTypes = correction.error_composition[0]
         errorTypesArray = Object.entries(errorTypes);
        errorTypesArray.sort((a, b) => b[1] - a[1]);
        errorTypesArray = errorTypesArray.slice(0, 4);
    }

    const errors = [
        { color: '#FF6633', type: 'Adjective'},
        { color: '#E6B333',  type: 'Adjective form' },
        { color: '#80B300',  type: 'Adverb' },
        { color: '#FF99E6', type: 'Conjunction' },
        { color: '#66994D', type: 'Contraction' },
        { color: '#66664D',  type: 'Determiner' },
        { color: '#FFB399', type: 'Morphology' },
        { color: '#3366E6',  type: 'Noun' },
        { color: '#809900',  type: 'Noun Number' },
        { color: '#CCFF1A', type: 'Noun Possessive' },
        { color: '#B366CC',  type: 'Noun inflection' },
        { color: '#559f97', type: 'Orthography' },
        { color: '#a84b4b',  type: 'Other' },
        { color: '#999966', type: 'Particle' },
        { color: '#E6B3B3',  type: 'Preposition' },
        { color: '#FF1A66',  type: 'Pronoun' },
        { color: '#4D8000',  type: 'Punctuation' },
        { color: '#E666FF',  type: 'Spelling' },
        { color: '#adad46',  type: 'Subject-Verb Agreement' },
        { color: '#99FF99',  type: 'Unknown' },
        { color: '#6680B3',  type: 'Verb' },
        { color: '#E6331A',  type: 'Verb Form' },
        { color: '#B33300',  type: 'Verb Inflection' },
        { color: '#4DB3FF',  type: 'Verb Tense' },
        { color: '#b0ae3c',type: 'Word Order' },
    ];
    // function to get the color of the error
    const getColor = (type) => {
        const error = errors.find(error => error.type === type);
        return error ? error.color : '#000000';
    }

    useEffect(() => {
        // Simuler le chargement des données
        setData(ussuesDetails.issues);
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }


    const handleOpenModal = () => {
        setVisible(true); // Afficher la modal lors du clic sur le bouton
    };

    const handleCloseModal = () => {
        setVisible(false); // Fermer la modal
    };
    return (
        console.log(data,'data'),
            <Card className={"side-card-title"} >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginBottom:"6%",marginLeft: '-2%' }}>
                    <div key="1" className={"font-weight-500"}>Issue Details</div>
                    {isCorrected ?
                    <div>
                        <button onClick={isCorrected ? handleOpenModal : null} className={"btn-open"}>
                            <Image src={open} preview={false}/>
                        </button>
                    </div>
                    : null}
                </div>
                <div className={"card-details"}>
                {isCorrected && errorTypesArray ? errorTypesArray.map((issue, key) => {
                    const error = errors.find((err) => err.type === issue[0]);
                    const backgroundColor = error ? error.color : '#FFFFFF'; // Default to white if no match found
                    return (
                    <div style={{textAlign: 'left', padding: 0}}
                            >
                                <div className={`circle circle-`} style={{backgroundColor}}>{issue[1]}</div>
                                <Text className={"text text-error-type"}>{issue[0]}</Text>
                            </div>
                        )}): (<div style={{textAlign: 'left', padding: 0}}></div>)}
                    </div>

                    <StatisticsModal visible={visible} handleCloseModal={handleCloseModal}/>
            </Card>
);
};

export default IssuesBlock;