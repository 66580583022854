import React from 'react';
import { Col,Layout, Button,message} from 'antd';
import '../sidebar/Sidebar.css';
import './SideCorrection.css';
import {SaveOutlined,CheckOutlined,CopyOutlined} from "@ant-design/icons";
const { Header, Content, Footer, Sider } = Layout;

const BtnCorrection = () => {
    const handleButtonClick = () => {
        message.success("Text Copied");
    };
    const handleButtonSaveClick = () => {
        message.success("Correction saved");
    };
    return (
                    <Col flex="auto">
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button onClick={handleButtonClick} type="default"  icon={<CopyOutlined/>} style={{ marginRight: 8 }} className={"button button-secondary button-small"}>
                                Copy
                            </Button>
                            <Button onClick={handleButtonSaveClick} type="default" icon={<SaveOutlined />}  style={{ marginRight: 8 }} className={"button button-secondary button-small"}>
                                Save
                            </Button>
                            <Button type="primary" icon={<CheckOutlined />}  style={{ marginRight: 8 }} className={"button button-small"}>
                                Correct
                            </Button>
                        </div>
                    </Col>
    );
};

export default BtnCorrection;