import { api } from './api';

export const Rubric = api.injectEndpoints({
    endpoints: (builder) => ({

        getRubrics: builder.query({
            query: () => ({
                url: '/api/rubrics',
                method: 'GET',

            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'Rubrics', id: 'LIST' },
                    ],
        }),
        getRubricById: builder.query({
            query: (id) => `/api/rubrics/${id}`,
        }),

        getRubricItem: builder.query({
            query: (id) => `${id}`,
        }),

        createRubric: builder.mutation({
            query: (rubricData) => {
                console.log("rubricData ==>", rubricData);
                return {
                    url: '/api/rubrics',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },

                    body: JSON.stringify(rubricData),
                }
            },
            invalidatesTags: [
                { type: 'Rubrics', id: 'LIST' },
            ],
        }),

        editRubric: builder.mutation({
            query: (rubricData) => {
                console.log("rubricData ==>", rubricData);
                return {
                    url: `/api/rubrics/${rubricData.id}`,
                    method: 'PATCH',
                    headers: {'Content-Type': 'application/merge-patch+json'},

                    body: JSON.stringify(rubricData),
                }
            },
            invalidatesTags: [
                {type: 'Rubrics', id: 'LIST'},
            ],
        }),

    }),
});

export const { useLazyGetRubricsQuery, useCreateRubricMutation, useLazyGetRubricByIdQuery, useLazyGetRubricItemQuery, useEditRubricMutation } = Rubric;