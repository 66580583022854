import { api } from './api';

export const StudentSubmission = api.injectEndpoints({
    endpoints: (builder) => ({


        createStudentSubmission: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/student-submissions`,
                method: 'POST',
                body: JSON.stringify(data),
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags: (result) =>
                result === undefined ? [] : [{ type: 'studentSubmission', id: "ALL" }],
        }),
        editStudentSubmissionContent: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/student-submissions/${id}`,
                method: 'PATCH',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/merge-patch+json' },
            }),
            invalidatesTags: (result) =>
                result === undefined ? [] : [{ type: 'studentSubmission', id: result['@id'] }],
        }),
    }),
});

export const { useCreateStudentSubmissionMutation , useEditStudentSubmissionContentMutation } = StudentSubmission;