import Sidebar from "../../Components/sidebar/Sidebar";
import Table from "../../Components/table/Table";
import { FormOutlined } from "@ant-design/icons"
import Code from "../../assets/images/code.svg";
import { Link } from "react-router-dom";
import {Button, Spin} from "antd";
import datat from "./data";
import "./rubric.css"
import AppLayout from "../Layout/AppLayout";

import {useLazyGetRubricsQuery, useLazyGetRubricByIdQuery} from "../../services/rubrics.api";

import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import ViewRubricModal from "../../Components/viewRubricModal/ViewRubricModal";
import EditRubricModal from "../../Components/rubricActions/editRubricModal/EditRubricModal.jsx";


const columns = [
    { key: 1 , title: '', dataIndex: 'id'},
    { key: 2 , title: 'Rubric Title', dataIndex: 'title'},
    { key: 3 , title: 'Type', dataIndex: 'type'},
    { key: 4 , title: 'Edit', dataIndex: 'Edit'},
    { key: 5 , title: '', dataIndex: 'Action'},
];




const Rubric = () => {

    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [rubricItem, setRubricItem] = useState(null);
    const navigate = useNavigate();
    const [rubricItemData, setRubricItemData] = useState(null);
    const [rubricItemDataEdit, setRubricItemDataEdit] = useState(null);

    const [trigger, { data: rubrics, isLoading: isLoadingRubric}] = useLazyGetRubricsQuery();

    const [getRubricItem, { data: rubricByItem, isLoading: isLoadingRubricItem }] = useLazyGetRubricByIdQuery();

    useEffect(() => {
        trigger();
    }, [trigger]);
    console.log('rubrics =>', rubrics);

    const initialValues = {
        name: '',
        grade: '',
        scoreTotal: 0,
        criteria: [
            {
                title: '',
                scores: [{ level: '', points: '', description: '' }]
            },
        ],
    };

    const handleActionClick = async (id) => {
        setRubricItem(id);
        console.log(id);
        const { data, error } = await getRubricItem(id);
        if (data) {
            setRubricItemData(data);
            setIsViewModalOpen(!isViewModalOpen);
        }
    };

    const handleEditRubric = async (id) => {
        console.log("edit rubric", id);
        const { data, error } = await getRubricItem(id);
        console.log("edit rubric data", data);
        if (data) {
            setRubricItemDataEdit(data);
            setIsEditModalOpen(!isEditModalOpen);
        }

    }

    const TableHeadCell = (column) => {
        if (column.dataIndex === 'id' || column.dataIndex === 'Action') {
            return column.title;
        }
        else {
            return (
                <>
                    <span>{column.title}</span>
                    <img src={Code} alt="code icon" width={16} />
                </>
            );
        }
    };

    const TableBodyCell = (column, item) => {
        switch (column.dataIndex) {
            case 'Action':
                return (
                    <Button onClick={() => handleActionClick(item.id)} className='tableBody__cell-openBtn'>
                        view
                    </Button>
                );

            case 'Format':
                return <span className='tableBody__cell-formatCol'>{item[column.dataIndex]}</span>;

            case "Edit":
                return <button className="tableBody__cell-edit" onClick={() => handleEditRubric(item.id)}>
                    <FormOutlined style={{fontSize : "18px", color:"#000095"}}/>
                </button>

            default:
                return item[column.dataIndex];
        }
    };

    const handleNewRubric = () => {
        navigate('/rubric/new');
        console.log("new rubric")
    }

    return (
        <AppLayout hideHeader={true}>
            <div className="rubricContent">
                <div className="rubricContent__header">
                    <div className="rubricContent__header-title">
                        <span>Rubric</span>
                    </div>

                    <div className="rubricContent__header-new">
                        <Link to="/rubric/new">
                            <Button type="primary" onClick={handleNewRubric}> New Rubric </Button>
                        </Link>
                    </div>
                </div>
                <Table
                    columns={columns}
                    data={rubrics ? rubrics['hydra:member'] : []}
                    TableHeadCell={TableHeadCell}
                    TableBodyCell={TableBodyCell}
                    useFilter={false}
                    useSearchBar
                    usePagination={true}
                    showEntriesPerPageSelect={true}
                    isLoading={isLoadingRubric}
                />

                <ViewRubricModal
                    isViewModalOpen={isViewModalOpen}
                    setIsViewModalOpen={setIsViewModalOpen}
                    rubricItem={rubricItem}
                    rubricItemData={rubricItemData}
                />

                {
                    rubricItemDataEdit && (
                        <EditRubricModal
                            isEditModalOpen={isEditModalOpen}
                            setIsEditModalOpen={setIsEditModalOpen}
                            rubricItemDataEdit={rubricItemDataEdit}
                        />
                    )
                }

            </div>
        </AppLayout>
    )
}

export default Rubric
