import { api } from './api';

export const StudentSubmissions = api.injectEndpoints({
    endpoints: (builder) => ({
        getStudentSubmissions: builder.query({
            query: () => ({
                url: '/api/student-submissions',
                method: 'GET',

            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'StudentSubmissions', id: 'LIST' },
                    ],
        }),
        getStudentSubmission: builder.query({
            query: (id) => ({
                url: `/api/student-submissions/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'StudentSubmissions', id: id }],
        }),
        submitOpenIAFeedback: builder.mutation({
            query: (openIAData) => ({
                url: '/api/student-submission-openia-call',
                method: 'POST',
                body: openIAData,
            }),
            invalidatesTags: [{ type: 'StudentSubmissions', id: 'LIST' }],
        }),


    }),
});

export const { useLazyGetStudentSubmissionsQuery,useGetStudentSubmissionQuery,useSubmitOpenIAFeedbackMutation } = StudentSubmissions;