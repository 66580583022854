import { Input, Button,Checkbox } from "antd";
import "./NewPassword.css"
import LeftArrow from "../../assets/images/left-arrow.svg";

const NewPassword = () => {
  return (
    <div className="container">
      <div className="createNewPassword">
        <h1 className="createNewPassword__title">Create new password</h1>
        <span className="createNewPassword__subTitle">
            Please create your new password, don&apos;t use your old password .
        </span>
          <form action="" className="createNewPassword__form">
              <Input.Password placeholder="New Password" type="password" size="large"
                              className="createNewPassword__form-password"/>
              <Input.Password placeholder="Confirem New Password" type="password" size="large"
                              className="createNewPassword__form-password"/>
              <Button type="primary" block className="createNewPassword__form-btn">
                  Reset and save my new password
              </Button>
              <a href="/sign-in" className="passwordRecovery__backToLogin" style={{marginTop: "20px"}}>
                  <img src={LeftArrow} alt="left arrow icon" width="20px"/>
                  <span>Back to sign in</span>
              </a>
          </form>
      </div>
    </div>
  )
}

export default NewPassword
